import { BlankImg, ProfilePicture } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import React, { useState } from "react";
import { Facebook, Instagram, Tiktok, Twitter } from "react-bootstrap-icons";
import HostUserMoreDetail from "./HostUserMoreDetail";
import * as moment from "moment";
import { formatSocialHandle } from "utils/removeHandle";
import { useEffectOnce } from "hooks/useEffectOnce";

const StorePerspectiveTable = ({ prospectives }) => {
  const [showUser, setShowUser] = useState(false);
  const handleCloseUser = () => setShowUser(false);
  const handleShowUser = () => setShowUser(true);
  const [hostProfile, setHostProfile] = useState();

  const profilePicture = () => {
    let response = fetch(prospectives?.profile_picture?.cloudinary_file);
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(prospectives?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffectOnce(() => {
    profilePicture();
  }, []);

  // const getSetupPercent = () => {
  //   if (prospectives) {
  //     if (
  //       prospectives?.completed_profile === true &&
  //       prospectives?.completed_venmo === true &&
  //       prospectives?.shopify_product_count > 0
  //     ) {
  //       return 75;
  //     } else if (
  //       (prospectives?.completed_profile === false &&
  //         prospectives?.completed_venmo === true &&
  //         prospectives?.shopify_product_count > 0) ||
  //       (prospectives?.completed_profile === true &&
  //         prospectives?.completed_venmo === false &&
  //         prospectives?.shopify_product_count > 0) ||
  //       (prospectives?.completed_profile === true &&
  //         prospectives?.completed_venmo === true &&
  //         prospectives?.shopify_product_count === 0)
  //     ) {
  //       return 50;
  //     } else if (
  //       (prospectives?.completed_profile === false &&
  //         prospectives?.completed_venmo === false &&
  //         prospectives?.shopify_product_count > 0) ||
  //       (prospectives?.completed_profile === false &&
  //         prospectives?.completed_venmo === true &&
  //         prospectives?.shopify_product_count === 0) ||
  //       (prospectives?.completed_profile === true &&
  //         prospectives?.completed_venmo === false &&
  //         prospectives?.shopify_product_count === 0)
  //     ) {
  //       return 25;
  //     } else if (
  //       prospectives?.completed_profile === false &&
  //       prospectives?.completed_venmo === false &&
  //       prospectives?.shopify_product_count === 0
  //     ) {
  //       return 0;
  //     }
  //   }
  // };
  return (
    <tr>
      <td className="image-title-wrapper">
        <img
          className="host-pp-small"
          onClick={handleShowUser}
          src={
            prospectives?.profile_picture?.file !== null
              ? hostProfile
              : BlankImg
          }
          alt="user"
        />
        <CustomModal
          showStatus={showUser}
          modalClass="profile_detail"
          clickStatus={handleCloseUser}
          buttonMessage="Close"
          closeStatus={handleCloseUser}
          title=" "
          message={<HostUserMoreDetail details={prospectives?.id} />}
          secondMessage="none"
          secondButton={false}
        />
        <span>
          {prospectives?.first_name} {(prospectives?.last_name).charAt(0)}.
        </span>
      </td>
      <td>{moment(prospectives?.created_at).format("MMMM Do YYYY")}</td>
      <td>{prospectives.email}</td>
      <td>
        {prospectives?.user_social?.instagram_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://www.instagram.com/${formatSocialHandle(
              prospectives?.user_social?.instagram_handle
            )}`}
          >
            <Instagram />
          </a>
        ) : (
          <></>
        )}{" "}
        {prospectives?.user_social?.tiktok_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://www.tiktok.com/@${formatSocialHandle(
              prospectives?.user_social?.tiktok_handle
            )}`}
          >
            <Tiktok />
          </a>
        ) : (
          <></>
        )}{" "}
        {prospectives?.user_social?.twitter_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://twitter.com/${formatSocialHandle(
              prospectives?.user_social?.twitter_handle
            )}`}
          >
            <Twitter />
          </a>
        ) : (
          <></>
        )}{" "}
        {prospectives?.user_social?.facebook_handle !== "" ? (
          <a
            target={"_blank"}
            rel="noreferrer"
            href={`https://www.facebook.com/${formatSocialHandle(
              prospectives?.user_social?.facebook_handle
            )}`}
          >
            <Facebook />
          </a>
        ) : (
          <></>
        )}
      </td>
      <td>{prospectives?.setup_percentage}%</td>
    </tr>
  );
};

export default React.memo(StorePerspectiveTable);
