import AuthContext from "context/AuthContext";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useContext, useState } from "react";

export function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
const AddEmail = () => {
  let { userProfile, updateMultipleEmails } = useContext(AuthContext);
  const [editing, setEditing] = useState(false);
  const [message, setMessage] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const getEmails = () => {
    const array = [];
    userProfile.user_shopify.map((store) => array.push(store.store_email));
    return { email: array };
  };

  const handleSubmit = (values) => {
    if (values?.email.length >= 1) {
      values = removeDuplicates(values.email);
      const newAccounts = [];
      values.map((email) => {
        let data = { store_email: email };
        return newAccounts.push(data);
      });
      let data = {
        phone_number: userProfile?.phone_number,
        about_me: userProfile?.about_me,
        social: {
          instagram_handle: userProfile?.user_social?.instagram_handle,
          twitter_handle: userProfile?.user_social?.twitter_handle,
          tiktok_handle: userProfile?.user_social?.tiktok_handle,
          facebook_handle: userProfile?.user_social?.facebook_handle,
        },
        location: {
          latitude: userProfile?.user_geo_location?.latitude,
          longitude: userProfile?.user_geo_location?.longitude,
          address: userProfile?.user_geo_location?.address,
          street_number: userProfile?.user_geo_location?.street_number,
          city: userProfile?.user_geo_location?.city,
          state: userProfile?.user_geo_location?.state,
          zip_code: userProfile?.user_geo_location?.zip_code,
        },
        profile_picture_id: userProfile?.profile_picture?.id,
        accounts: newAccounts,
      };
      const res = updateMultipleEmails(data);
      res
        .then(function (response) {
          setMessage({ text: response.data.message, success: true });
          setEditing(false);
        })
        .catch(function (error) {
          setMessage({ text: error?.response.data.message, success: false });
        });
    } else {
      setMessage({ text: "At least one email is required", success: false });
    }
  };
  return (
    <div className="card cardc mt-3">
      <ul className="list-group list-group-flush">
        <li className="list-group-item justify-content-between align-items-center">
          <h6 className="mb-0">Shopping History Emails:</h6>
          {/* <span className="text-secondary"> */}
        </li>
      </ul>
      {message ? (
        <>
          {message.success ? (
            <p style={{ color: "#359CD7", marginLeft: "10px" }}>
              {message.text}
            </p>
          ) : (
            <p style={{ color: "red", marginLeft: "10px" }}>{message.text}</p>
          )}
        </>
      ) : (
        <></>
      )}
      {editing || queryParams?.get("edit") === "true" ? (
        <div>
          <Formik initialValues={getEmails()} onSubmit={handleSubmit}>
            {({ values }) => (
              <Form>
                <FieldArray
                  name="email"
                  validateOnChange
                  render={(arrayHelpers) => (
                    <div>
                      {values.email &&
                        values.email.length > 0 &&
                        values.email.map((email, index) => (
                          <div key={index}>
                            <div className="form-outline d-flex">
                              <Field
                                name={`email.${index}`}
                                type="email"
                                className="form-control additional-email"
                              />
                              <button
                                type="button"
                                className="remove_email_button"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                -
                              </button>
                            </div>
                          </div>
                        ))}
                      <div>
                        <button
                          type="button"
                          className="add_email_button"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {/* show this when user has removed all email from the list */}
                          Add email
                        </button>
                        <button
                          type="submit"
                          className="submit_add_email_button"
                          disabled={!values.email}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="normal_view_emails">
          <div className="email_list_wrap">
            <ol>
              {userProfile.user_shopify.map((store, index) => (
                <li key={index}>{store.store_email}</li>
              ))}
            </ol>
          </div>

          <button className="add_email_button" onClick={() => setEditing(true)}>
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default AddEmail;
