import { base64Decode } from "@firebase/util";
import ApiContext from "context/ApiContext";
import React, { useContext, useState } from "react";
import GetTimer from "utils/getTimer";

const PhoneVerifyPage = ({
  phoneNumber,
  userId,
  handleClose,
  updateProfile,
}) => {
  let { getUserOtp, putUserOtp } = useContext(ApiContext);
  const [sentOtp, setSentOtp] = useState(false);
  const [otpText, setOtpText] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [resMessage, setResMessage] = useState(null);
  const [resOtp, setResOtp] = useState(null);
  const [otpSendingStatus, setOtpSendingStatus] = useState(false);

  const handleOtpTime = () => {
    setSentOtp(true);
    setTimeout(() => {
      setSentOtp(false);
    }, 3 * 60 * 1000);
  };
  const handleOTP = (e) => {
    // setOtpShow(true);
    setOtpSendingStatus(true);
    const data = {
      number: e,
    };
    const res = getUserOtp(data);
    res
      .then(function (response) {
        if (response.status === 200) {
          handleOtpTime();
          setResOtp(response.data.data);
          setOtpShow(true);
          setOtpSendingStatus(false);
          setResMessage(
            "Check your phone for a verification code and enter it below."
          );
        }
      })
      .catch(function (error) {
        setResMessage("Error sending OTP.");
        console.log(error);
        setOtpSendingStatus(false);
      });
  };
  const checkOTP = () => {
    setOtpSendingStatus(true);
    const data = {
      number: phoneNumber,
    };
    setResMessage("Checking...");
    var code = base64Decode(resOtp.code);
    if (otpText === code) {
      const res = putUserOtp(data, userId, `?verify=true`);
      res
        .then(async function (response) {
          setResMessage("Successfully verified, closing page automatically");
          setOtpShow(false);
          setOtpSendingStatus(false);
          if (await updateProfile()) {
            handleClose();
          }
        })
        .catch(function (error) {
          setResMessage("Verification failed");
          setTimeout(() => {
            setResMessage(null);
          }, 5000);
          console.log(error);
          setOtpSendingStatus(false);
        });
    } else {
      setResMessage("Otp verification failed");
      setTimeout(() => {
        setResMessage(null);
      }, 5000);
      setOtpSendingStatus(false);
    }
  };
  return (
    <div className="d-block">
      <div>
        <p>
          By entering your number, you agree to receive text messages at the
          phone number provided.
        </p>
      </div>
      {sentOtp ? (
        <div className="send-otp-wrap">
          Send again in <GetTimer />
        </div>
      ) : (
        <button
          disabled={otpSendingStatus}
          onClick={() => handleOTP(phoneNumber)}
          className="otp-btn-wrap btn btn-primary"
        >
          {!otpSendingStatus ? "Send OTP" : "Sending"}
        </button>
      )}
      <div className="response-otp-message">
        <p>{resMessage ? resMessage : ""}</p>
      </div>
      {otpShow ? (
        <div className="d-flex">
          <div className="col col-md-4 mt-1 mb-2">
            <input
              type="text"
              placeholder="OTP"
              name="otpCode"
              value={otpText}
              className={`form-control `}
              onChange={(e) => setOtpText(e.target.value)}
            />
          </div>
          <div className="col col-4 mt-1 mb-2">
            <button
              disabled={otpText === ""}
              onClick={checkOTP}
              className="btn send-otp-button"
            >
              {!otpSendingStatus ? "Verify OTP" : "Verifying"}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PhoneVerifyPage;
