import { ProfilePicture } from "assets/images";
import React from "react";
import "./payout.css";

const PayoutAccepted = (props) => {
  const gettimestring = (e) => {
    const date1 = new Date(e);
    return date1.toDateString();
  };
  return (
    <>
      <tr className="payout-table-items">
        <td>
          <div className="row payout-image--title-wrapper">
            <div className="col-sm-4 col-md-4 image-wrap">
              <img
                className="host-pp-small"
                src={
                  props?.hostDetails?.product
                    ? props.hostDetails.product.image
                    : ProfilePicture
                }
                alt="user"
              />{" "}
            </div>
            <div className="col-7 col-md-8">
              <span>
                <p>
                  {props?.hostDetails?.product
                    ? props?.hostDetails?.product.name
                    : "Product Name"}
                  <br />
                  <span className="date-payout">
                    {gettimestring(props?.hostDetails?.created_at)}
                  </span>
                </p>
              </span>
            </div>
          </div>
        </td>
        <td>
          <p>
            <strong>Demo</strong>
          </p>
        </td>
        <td>
          <p>
            <strong>${props?.hostDetails?.earning.earning}</strong>
          </p>
        </td>
        <td>
          {props?.hostDetails?.earning.earning_paid === null ? (
            <p>
              <strong>Pending</strong>
            </p>
          ) : (
            <p className="paid-text">Paid</p>
          )}
        </td>
      </tr>
      {props?.hostDetails?.earning.sales_commission === "0.00" ? (
        <></>
      ) : (
        <tr className="payout-table-items">
          <td>
            <div className="row">
              <div className="col-sm-4 col-md-4 image-wrap">
                <img
                  className="host-pp-small"
                  src={
                    props?.hostDetails?.product.image
                      ? props.hostDetails.product.image
                      : ProfilePicture
                  }
                  alt="user"
                />{" "}
              </div>
              <div className="col col-sm-6 col-md-8">
                <span>
                  <p>
                    {props?.hostDetails?.product.name} <br />
                    <span className="date-payout">
                      {" "}
                      {gettimestring(props?.hostDetails?.created_at)}
                    </span>
                  </p>
                </span>
              </div>
            </div>
          </td>
          <td>
            <p>
              <strong>Sales Commission</strong>
            </p>{" "}
          </td>
          <td>
            <p>
              <strong>${props?.hostDetails?.earning.sales_commission}</strong>
            </p>
          </td>
          <td>
            {props?.hostDetails?.earning.sales_commission_paid === null ? (
              <p>
                <strong>Pending</strong>
              </p>
            ) : (
              <p className="paid-text">Paid</p>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default PayoutAccepted;
