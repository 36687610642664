import React, { useState, useRef, useContext } from "react";
import AuthContext from "context/AuthContext";
import DataContext from "context/DataContext";
import { Dropdown, Form, Overlay, Tooltip } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";
import { useEffectOnce } from "hooks/useEffectOnce";
import { Rating } from "react-simple-star-rating";

const ProductCardSnippet = () => {
  const { softReviews } = useContext(DataContext);
  const { userProfile } = useContext(AuthContext);
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [starColor, setStarColor] = useState("#ffcd3a");
  const [allProducts, setAllProducts] = useState(null);
  const [productName, setProductName] = useState();
  const [productNameFont, setProductNameFont] = useState(30);
  const [avgProductStar, setAvgProductStar] = useState(null);

  const generateDynamicCode = () => {
    const htmlCode = ` 
    <div id="rating" class="rating" data-starColor="${starColor}" data-avgStars="${avgProductStar}" data-productName="${productName}" data-productName="${productNameFont}">
    </div> 
    <script src="https://purebrand-storage.s3.us-east-2.amazonaws.com/productCardSnippet.js"></script>
          `;
    return htmlCode;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generateDynamicCode());
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };

  const allReviews = async (params) => {
    try {
      const res = await softReviews(
        null,
        params + `&store_id=${userProfile.store.store_id}`,
        3
      );
      setAllProducts(res.data.data.products);
    } catch (error) {
      setAllProducts(null);
    }
  };

  useEffectOnce(() => {
    allReviews(`?order=true`);
  }, []);

  const handleProductSelection = (event) => {
    const selectedProductName = event.target.value;
    const selectedProduct = allProducts.find(
      (product) => product.details.name === selectedProductName
    );

    if (selectedProduct) {
      setProductName(selectedProductName);
      setAvgProductStar(selectedProduct.details.star);
    } else {
      setProductName(null);
      setAvgProductStar(null);
    }
  };

  return (
    <div>
      <div className="code-snippet-codes mb-3 mt-3">
        <code
          style={{
            display: "block",
            background: "#000000",
            borderRadius: "12px",
          }}
          className="snippet_code"
        >
          <div className="code-title">
            <p className="code-language">HTML </p>
            <button
              className="copy-button"
              ref={target}
              onClick={handleCopyClick}
            >
              <Clipboard size={20} color="#fff" />
            </button>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Copied!
                </Tooltip>
              )}
            </Overlay>
          </div>
          <div className="code-text">
            <pre>{generateDynamicCode()}</pre>
          </div>
        </code>
      </div>
      <div className="row py-5">
        <div className="col-sm-12 col-md-5 ">
          <div className="col-sm-12 col-md-4 product">
            <h5>Select Product</h5>
            <select
              className=" form-select "
              defaultValue="none"
              style={{ width: "auto" }}
              onChange={(e) => handleProductSelection(e)}
            >
              <option value="none" disabled>
                Select Product
              </option>
              {allProducts &&
                allProducts.map((data) => {
                  return (
                    <option key={data.id} value={data.details.name}>
                      {data.details.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        {productName && (
          <>
            <div className="col-sm-12 col-md-12 ">
              <div className="color">
                <h6>Set Color </h6>
                <form className="form-star">
                  <input
                    type="color"
                    value={starColor}
                    className="form-control-color"
                    style={{ background: "none", borderColor: "#ccc" }}
                    onChange={(e) => setStarColor(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div className="col-sm-12 col-md-5">
              <div className="btnText">
                <h6>Set Product Name Font Size </h6>
                <form className="form-star" style={{ minWidth: "200px" }}>
                  <input
                    type="number"
                    className="form-control px-3 "
                    min={0}
                    onChange={(e) => {
                      setProductNameFont(e.target.value);
                    }}
                    value={productNameFont}
                  />
                </form>
              </div>
            </div>
          </>
        )}
      </div>

      <hr />
      <h5>Preview </h5>
      {productName && (
        <div className="rating-card-view wrapper-rating">
          <div className="quote-container">
            <div className="reviewer-details">
              <div className="star-rating-div">
                <span className="title" style={{ fontSize: "12px" }}>
                  Average Rating
                </span>
                <br />
                <Rating
                  size={30}
                  readonly
                  fillColor={starColor}
                  transition
                  allowFraction
                  initialValue={avgProductStar}
                  style={{ marginBottom: "10px" }}
                />
                <div>
                  <p
                    className="title productName"
                    style={{
                      fontSize: `${productNameFont}px`,
                      fontWeight: "600",
                    }}
                  >
                    {productName}
                  </p>
                </div>
              </div>
              <span
                style={{ fontSize: `${productNameFont}px`, color: "#000" }}
              ></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCardSnippet;
