import CustomModal from "components/Modal/CustomModal";
import PurebrandToast from "components/Toasts/Toast";
import React, { useContext, useState } from "react";
import { Send, Trash3, XCircle } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import MessageContext from "../../context/MessageContext";

const DemoMessageOut = ({ sendText, setSendText }) => {
  let { createNewMessage } = useContext(MessageContext);
  const loc = useLocation();
  const [newChat, setNewChat] = useState();
  const [showToast, setShowToast] = useState(false);
  const [failed, setFailed] = useState(false);

  const toastPopup = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };
  const handleCloseToast = () => {
    setShowToast(false);
  };

  const sendMessage = () => {
    let demoId = loc?.state?.eventDetail?.id;
    let payload = {
      id: demoId,
      text: newChat,
    };
    const response = createNewMessage(payload);
    response
      .then(function (res) {
        setNewChat("");
        toastPopup();
      })
      .catch(function (err) {
        toastPopup();
        setFailed(true);
      });
  };

  return (
    <>
      <PurebrandToast
        show={showToast}
        onClose={handleCloseToast}
        title={!failed ? "Success" : "Failed"}
        message={
          !failed
            ? "Your message has been sent successfully."
            : "Message Sending Failed, Try agian"
        }
      />
      <div className="row">
        <div className="col-7 ">
          <textarea
            className="form-control form-control "
            type="text"
            placeholder="Type Message..."
            rows="5"
            style={{ borderRadius: "2px", width: "100%" }}
            value={newChat}
            onChange={(e) => {
              setNewChat(e.target.value);
            }}
          />
        </div>
        <div className="col-5 p-0">
          <div className="row">
            <div className="col-4 text-end ">
              <button
                className="btn"
                style={{
                  border: "1px solid #ccc",
                }}
                onClick={sendMessage}
              >
                <Send style={{ color: "green", fontSize: "25px" }} />
              </button>
            </div>
            <div className="col-4 text-end">
              <button
                className="btn"
                onClick={() => setNewChat("")}
                style={{
                  border: "1px solid #ccc",
                }}
              >
                <Trash3 style={{ color: "red", fontSize: "25px" }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoMessageOut;
