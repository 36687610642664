import AuthContext from "context/AuthContext";
import DataContext from "context/DataContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Carousel, Dropdown, Overlay, Spinner, Tooltip } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";
import { Rating } from "react-simple-star-rating";
import TagsInput from "react-tagsinput";

const SnippetViewer = () => {
  const { userProfile } = useContext(AuthContext);
  const { softReviews } = useContext(DataContext);
  const [loadingData, setLoadingData] = useState(false);
  const [colorHash, setColorHash] = useState("#ffcd3a");
  const [orderSort, setOrderSort] = useState(true);
  const [limit, setLimit] = useState(10);
  const [tags, setTags] = useState("");
  const [myTags, setMyTags] = useState([]);
  const [stars, setStars] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [prodArray, setprodArray] = useState([]);
  let [productFilter, setProductFilter] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const [show, setShow] = useState(false);
  const [productNameFont, setProductNameFont] = useState(25);
  const [userNameFont, setUserNameFont] = useState(20);
  const [commentFont, setCommentFont] = useState(15);
  const [storeNameFont, setStoreNameFont] = useState(15);

  const target = useRef(null);

  const generateDynamicCode = () => {
    const htmlCode = ` 
    <div id="rating" class="rating" data-starColor="${colorHash}" data-subDomain="${
      process.env.REACT_APP_SUBDOMAIN
    }" data-filterTags="order=${orderSort}${
      tags === "" ? "" : `&tags=${tags}&tag=${tags}`
    }${stars ? `&stars=${stars}` : ""}&limit=${limit}${
      productFilter ? `&product=${productFilter}` : ""
    }" data-storeId="${userProfile.store.store_id}">
      </div> 
      <script src="https://purebrand-storage.s3.us-east-2.amazonaws.com/seeAllReviews.js"></script>
      <style>
      .productName{font-size:${productNameFont}px}
      .userName{font-size:${userNameFont}px}
      .comment{font-size:${commentFont}px}
      .storeName{font-size:${storeNameFont}px}
      </style>
    `;
    return htmlCode;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generateDynamicCode());
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };

  const handleColorHashChange = (event) => {
    setColorHash(event.target.value);
  };

  const getRating = (data) => {
    return data.map((p, i) => {
      return (
        <Carousel.Item key={i}>
          <div className="wrapper-rating">
            <div className="quote-container">
              <div
                className="reviewer-details"
                style={{ borderBottom: "2px solid #000", width: "100%" }}
              >
                <div className="star-rating-div">
                  <div>
                    <span
                      className="title productName"
                      style={{
                        fontSize: `${productNameFont}px`,
                        fontWeight: "600",
                      }}
                    >
                      {p.soft_product.name}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="reviewer-details"
                style={{
                  borderBottom: "2px solid #000",
                  width: "100%",
                  paddingBottom: "10px",
                }}
              >
                <span
                  className="name userName"
                  style={{
                    fontSize: `${userNameFont}px`,
                  }}
                >
                  {p.soft_user.first_name}
                  {/* {p.soft_user.last_name.charAt(0)}. */}
                </span>
                <span
                  className="title comment"
                  style={{
                    fontSize: `${commentFont}px`,
                  }}
                >
                  {p.comment}
                </span>
                <div className="star-rating-div">
                  <Rating
                    size={20}
                    readonly
                    fillColor={colorHash}
                    transition
                    allowFraction
                    initialValue={p.stars}
                  />
                </div>
              </div>

              <div className="reviewer-details">
                <span
                  className="title storeName"
                  style={{
                    fontSize: `${storeNameFont}px`,
                  }}
                >
                  Purchased from{" "}
                  <strong>{p.soft_product.soft_store.name}</strong>
                </span>
              </div>
            </div>
          </div>
        </Carousel.Item>
      );
    });
  };

  const allReviews = async (params) => {
    setLoadingData(true);
    try {
      const res = await softReviews(
        null,
        params + `&store_id=${userProfile.store.store_id}`,
        3
      );
      if (res) {
        let arr = [];
        await res.data.data.soft_review.results.map((data) => arr.push(data));
        await res.data.data.soft_host_review.results.map((data) =>
          arr.push(data)
        );
        setReviewData(arr);
        setAllProducts(res.data.data.products);
        setLoadingData(false);
      }
    } catch (error) {
      setLoadingData(true);
    }
  };

  useEffectOnce(() => {
    allReviews(`?order=${orderSort}&limit=${limit}`);
  }, []);

  const getMenuItems = () => {
    if (allProducts) {
      return allProducts.map((data, i) => (
        <div key={i} className="p-2">
          <input
            type="checkbox"
            className="form-check-input custom_check"
            checked={prodArray.includes(data.id)}
            onChange={(event) => {
              if (event.target.checked) {
                setprodArray((prevProdArray) => [...prevProdArray, data.id]);
              } else {
                const index = prodArray.indexOf(data.id);
                if (index > -1) {
                  // only splice array when item is found
                  prodArray.splice(index, 1);
                  setprodArray(prodArray);
                }
              }
            }}
          />
          <span style={{ fontSize: "14px", paddingLeft: "5px" }}>
            {data.name}
          </span>
        </div>
      ));
    }
    return;
  };

  return (
    <>
      <div className="code-snippet-codes mb-3 mt-3">
        <code
          style={{
            display: "block",
            background: "#000000",
            borderRadius: "12px",
          }}
          className="snippet_code"
        >
          <div className="code-title">
            <p className="code-language">HTML </p>
            <button
              className="copy-button"
              ref={target}
              onClick={handleCopyClick}
            >
              <Clipboard size={20} color="#fff" />
            </button>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Copied!
                </Tooltip>
              )}
            </Overlay>
          </div>
          <div className="code-text">
            <pre>{generateDynamicCode()}</pre>
          </div>
        </code>
      </div>
      {/* code toolbar */}
      <div className="row toolbar">
        <div className="col-sm-12 col-md-4 color">
          <h5>Set Color</h5>
          <div className="edit-snippet">
            <form onSubmit={handleColorHashChange} className="form-star">
              <label style={{ marginRight: "10px" }}>Star color </label>
              <input
                type="color"
                id="color1"
                value={colorHash}
                className="color-input-round"
                onChange={handleColorHashChange}
              />
            </form>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 order">
          <h5>Order</h5>
          <input
            type="checkbox"
            name="order"
            defaultChecked={orderSort}
            onClick={() => {
              setOrderSort(!orderSort);
            }}
          />
          <span style={{ paddingLeft: "10px" }}>Order</span>
        </div>
        <div className="col-sm-12 col-md-4 tags">
          <h5>Tags</h5>
          <div className="input-label">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Tags
              </span>
            </div>
            <TagsInput
              className=""
              value={myTags}
              onChange={(e) => {
                setMyTags(e);
                let tagText = "";
                e.map((tag) => (tagText = tagText + tag + ","));
                setTags(tagText);
              }}
            />
            {/* <input
              className="form-control input-label  px-3"
              ref={ref}
              type="text"
              placeholder="Eg. Electronics,clothes"
              onChange={(e) => {
                setTags(e.target.value);
                setSnippet(`
      <div id="rating" class="rating" data-starColor="${colorHash}" data-subDomain="${
                  process.env.REACT_APP_SUBDOMAIN
                }" data-filterTags="order=${orderSort}${
                  e.target.value === ""
                    ? ""
                    : `&tags=${e.target.value}&tag=${e.target.value}`
                }${stars ? `&stars=${stars}` : ""}&limit=${limit}${
                  productFilter ? `&product=${productFilter}` : ""
                }" data-storeId="${userProfile.store.store_id}">
      </div> 
      <script src="https://purebrand-storage.s3.us-east-2.amazonaws.com/seeAllReview.js"></script>
    `);
              }}
            /> */}
          </div>
        </div>
        <div className="col-sm-12 col-md-4 limit">
          <h5>Limit</h5>
          <div className="input-label">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                Limit
              </span>
            </div>
            <input
              type="number"
              className="form-control  px-3 limit_input"
              placeholder="10"
              aria-label="Limit"
              aria-describedby="basic-addon1"
              min={0}
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 product">
          <h5>Select Product</h5>
          <Dropdown className="product_drop input-label">
            <Dropdown.Toggle
              className=" d-flex text-center form-select"
              variant="image"
              id="dropdown-basic"
              style={{ border: "0px", paddingLeft: "15px" }}
            >
              Select Product
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {getMenuItems()}
              {reviewData?.length > 0}
              <div className="d-flex justify-content-center">
                <button
                  className="small_apply_button"
                  onClick={() => {
                    let word = "";
                    prodArray.forEach((arr) => (word = word + arr + ","));
                    setProductFilter(word);
                  }}
                >
                  Apply
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-sm-12 col-md-4 rate">
          <h5>Rating</h5>
          <div className="px-2">
            <Rating
              size={25}
              transition
              allowFraction
              initialValue={stars ? stars : 0}
              onClick={(e) => {
                setStars(e);
              }}
            />
          </div>
        </div>
        <div className=" fontsize mt-3">
          <h5>Set Font Size</h5>
          <div className="row">
            <div className="input-label product-name col-sm-12 col-md-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Product Name
                </span>
              </div>
              <input
                type="number"
                className="form-control px-3 "
                min={0}
                value={productNameFont}
                onChange={(e) => {
                  setProductNameFont(Number(e.target.value));
                }}
              />
            </div>
            <div className="input-label user col-sm-12 col-md-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  User Name
                </span>
              </div>
              <input
                type="number"
                className="form-control px-3 limit_input"
                min={0}
                value={userNameFont}
                onChange={(e) => {
                  setUserNameFont(Number(e.target.value));
                }}
              />
            </div>
            <div className="input-label comment col-sm-12 col-md-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Comment
                </span>
              </div>
              <input
                type="number"
                className="form-control px-3 limit_input"
                min={0}
                value={commentFont}
                onChange={(e) => {
                  setCommentFont(Number(e.target.value));
                }}
              />
            </div>
            <div className="input-label store col-sm-12 col-md-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Product Name
                </span>
              </div>
              <input
                type="number"
                className="form-control  px-3 limit_input"
                min={0}
                value={storeNameFont}
                onChange={(e) => {
                  setStoreNameFont(Number(e.target.value));
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 filter">
          <h5>&nbsp;</h5>
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div
                className="edit-snippet input-label px-3"
                style={{ color: "#fff", background: "#359cd7" }}
                onClick={() =>
                  allReviews(
                    `?order=${orderSort}${
                      tags === "" ? "" : `&tags=${tags}&tag=${tags}`
                    }${stars ? `&stars=${stars}` : ""}&limit=${limit}${
                      productFilter ? `&product=${productFilter}` : ""
                    }`
                  )
                }
              >
                Apply Filter
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div
                className="edit-snippet input-label px-3"
                onClick={() => {
                  allReviews(`?order=${true}&limit=10`);
                  setOrderSort(true);
                  setLimit(10);
                  setTags("");
                  setprodArray([]);
                  setProductFilter(null);
                  setStars(null);
                  setColorHash("#ffcd3a");
                  setProductNameFont(25);
                  setUserNameFont(20);
                  setStoreNameFont(15);
                  setCommentFont(15);
                }}
              >
                Clear Filter
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 className="mt-5">Preview :</h5>

      <div id="rating" className="rating">
        {loadingData ? (
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="grow" /> <Spinner animation="grow" />
            <Spinner animation="grow" />
          </div>
        ) : (
          <Carousel>{reviewData && getRating(reviewData)}</Carousel>
        )}
      </div>
    </>
  );
};

export default SnippetViewer;
