import { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CardElement.css";
import PaymentContext from "context/PaymentContext";
import CustomModal from "components/Modal/CustomModal";

const UpdatePaymentForm = ({
  sureShow,
  setSureShow,
  showBankFormUpdate,
  setShowBankFormUpdate,
}) => {
  const { sendCardTokenUpdated } = useContext(PaymentContext);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resposneForm, setResponseForm] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [inputGiven, setInputGiven] = useState(false);
  const handleCloseResponse = () => setResponseForm(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    try {
      let res = await sendCardTokenUpdated(paymentMethod.id);
      if (res.status === 400) {
        setResponseForm(true);
        setResponseMessage(res?.data?.error);
      }
      if (res.status === 200) {
        setResponseForm(true);
        setResponseMessage(res?.data?.message);
      }
    } catch (err) {
      if (error) {
        setMessage(error.message);
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const card_options = {
    style: {
      base: {
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="cardc">
          <div className="m-5 cardc" id="card-element">
            <CardElement
              options={card_options}
              onChange={() => {
                setInputGiven(true);
              }}
            />
          </div>
          <div className="text-center mb-3">
            <button
              disabled={isLoading || !stripe || !elements}
              className="btn bg-primary text-white"
              hidden={!inputGiven}
              id="submit"
            >
              <span id="button-text">
                {isLoading ? "Updating Details..." : "Update Details"}
              </span>
            </button>
          </div>
        </div>
        {message && <div id="payment-message text-danger">{message}</div>}
      </form>
      <CustomModal
        showStatus={resposneForm}
        clickStatus={() => {
          handleCloseResponse();
          setSureShow(!sureShow);
          setShowBankFormUpdate(!showBankFormUpdate);
        }}
        buttonMessage="Close"
        message={responseMessage}
        closeStatus={() => {
          handleCloseResponse();
          setSureShow(!sureShow);
          setShowBankFormUpdate(!showBankFormUpdate);
        }}
      />
    </>
  );
};

export default UpdatePaymentForm;
