import { ApiProvider } from './ApiContext';
import { AuthProvider } from './AuthContext';
import { DataProvider } from './DataContext';
import { DemoProvider } from './DemoContext';
import { NotificationProvider } from './NotificationContext';
import { PaymentProvider } from './PaymentContext';
import { ProfileProvider } from './ProfileContext';
import { VideoProvider } from './VideoContext';
import { LogProvider } from './LogContext';
import { MessageProvider } from './MessageContext';
import { SocketProvider } from './SocketContext';
import { DotsProvider } from './DotsContext';

export const PurebrandProvider = ({ children }) => {
  return (
    <AuthProvider>
      <ApiProvider>
        <ProfileProvider>
          <DemoProvider>
            <LogProvider>
              <NotificationProvider>
                <PaymentProvider>
                  <DotsProvider>
                    <VideoProvider>
                      <MessageProvider>
                        <SocketProvider>
                          <DataProvider>{children}</DataProvider>
                        </SocketProvider>
                      </MessageProvider>
                    </VideoProvider>
                  </DotsProvider>
                </PaymentProvider>
              </NotificationProvider>
            </LogProvider>
          </DemoProvider>
        </ProfileProvider>
      </ApiProvider>
    </AuthProvider>
  );
};
