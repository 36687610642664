import PurebrandLoader from "components/Loader/PurebrandLoader";
import DataContext from "context/DataContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import { Table } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";
import { getOffsetFromURL } from "utils/stringFormatter";
import ReviewViewTable from "./ReviewViewTable";

const SoftReviewAdmin = () => {
  const { softReviews } = useContext(DataContext);
  const [sortStatus, setSortStatus] = useState(false);
  const [dataReview, setDataReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);
  const [count, setCount] = useState(0);

  const allReviews = async (params) => {
    setLoading(true);
    try {
      const res = await softReviews(null, params, 1);
      if (res) {
        setPrev(res.data.data.previous);
        setNext(res.data.data.next);
        setDataReview(res.data.data.results);
        setCount(res.data.data.count);
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
    }
  };
  if (next) {
    var nextPathname = new URL(next).search;
  }
  if (prev) {
    var prevPathname = new URL(prev).search;
  }
  const getReviews = () => {
    if (dataReview) {
      if (count === 0) {
        return (
          <tr>
            <td>No soft reviews yet!</td>
          </tr>
        );
      }
      return dataReview.map((data, i) => {
        return <ReviewViewTable review={data} key={i} />;
      });
    }
  };
  useEffectOnce(() => {
    allReviews("?order=true&limit=20&offset=0");
  }, []);

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <div>
        <Table className="table-store" responsive>
          <thead>
            <tr>
              <th scope="col">
                <div className="arrows">
                  Name{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setSortStatus(!sortStatus);
                      allReviews(`?host=${sortStatus}&limit=20&offset=0`);
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="arrows">
                  Email{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setSortStatus(!sortStatus);
                      allReviews(`?email=${sortStatus}&limit=20&offset=0`);
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="arrows">
                  Product{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setSortStatus(!sortStatus);
                      allReviews(`?product=${sortStatus}&limit=20&offset=0`);
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="arrows">
                  Stars{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setSortStatus(!sortStatus);
                      allReviews(`?stars=${sortStatus}&limit=20&offset=0`);
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="arrows">
                  Rating{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setSortStatus(!sortStatus);
                      allReviews(`?stars=${sortStatus}&limit=20&offset=0`);
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th>Comment</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>{getReviews()}</tbody>
        </Table>
        <div className="text-center mt-3">
          {!loading ? (
            <button
              onClick={() => {
                allReviews(prevPathname);
              }}
              disabled={!prev}
              className=" previous-button-product"
              style={{ backgroundColor: "#359CD7" }}
            >
              Prev
            </button>
          ) : (
            <></>
          )}
          {!next && !prev && !loading ? `0-${count}/${count}` : null}
          {next && !loading
            ? `${parseInt(getOffsetFromURL(next)) - 20}-${getOffsetFromURL(
                next
              )}/${count}`
            : null}

          {prev && !next && !loading
            ? `${parseInt(getOffsetFromURL(prev)) + 20}-${count}/${count}`
            : null}
          {!loading ? (
            <button
              onClick={() => {
                allReviews(nextPathname);
              }}
              disabled={!next}
              className=" next-button-product"
              style={{ backgroundColor: "#359CD7" }}
            >
              Next
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
};

export default SoftReviewAdmin;
