import Dashelement from "components/Dashboard/Dashelement";
import AuthContext from "context/AuthContext";
import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const IncompleteProfile = () => {
  let { user } = useContext(AuthContext);

  const navigate = useNavigate();
  if (user?.role === 3) {
    return (
      <div>
        <Dashelement className="features add-product-initial">
          <div className="text-wrapper-productadder">
            <h3 className="text-center">Completed your profile?</h3>
            <p className="text-center">
              We require you to complete your profile before you continue with
              other features. <br />
              Completing your profile helps you get demo request and increase a
              chance of acceptance.{" "}
            </p>
            <div className="button-wraper-productadder text-center">
              <Button onClick={() => navigate("/user/completeprofile")}>
                Complete profile
              </Button>
            </div>
          </div>
        </Dashelement>
      </div>
    );
  } else if (
    user?.role === 2 ||
    user.role === 4 ||
    user.role === 5 ||
    user.role === 6
  ) {
    return (
      <div>
        <Dashelement className="features add-product-initial">
          <div className="text-wrapper-productadder">
            <h3 className="text-center">Completed your profile?</h3>
            <p className="text-center">
              We require you to complete your profile before you continue with
              other features. <br />
              Completing your profile is required to setup discount and
              comission and let Purebrand know about you and your store.{" "}
            </p>
            <div className="button-wraper-productadder text-center">
              <Button onClick={() => navigate("/user/completeprofile")}>
                Complete profile
              </Button>
            </div>
          </div>
        </Dashelement>
      </div>
    );
  } else if (user?.role === 1) {
    return (
      <div>
        <Dashelement className="features add-product-initial">
          <div className="text-wrapper-productadder">
            <h3 className="text-center">Completed your profile?</h3>
            <p className="text-center">
              We require you to complete your profile before you continue with
              other features. <br />
              Completing your profile is required to setup discount and
              comission and let Purebrand know about you and your store.{" "}
            </p>
            <div className="button-wraper-productadder text-center">
              <Button onClick={() => navigate("/user/completeprofile")}>
                Complete profile
              </Button>
            </div>
          </div>
        </Dashelement>
      </div>
    );
  }
};

export default IncompleteProfile;
