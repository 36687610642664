import React, { useContext, useEffect } from "react";
import PaymentContext from "context/PaymentContext";
import { useLocation } from "react-router-dom";
import PurebrandLoader from "components/Loader/PurebrandLoader";

const StripeComplete = () => {
  const { onBoardHostComplete } = useContext(PaymentContext);

  const loc = useLocation();
  const account_id = loc?.pathname.split("/")[3];
  const user_id = loc?.pathname.split("/")[2];

  const data = {
    uid: user_id,
    account_id: account_id,
  };

  useEffect(() => {
    const completeOnBoarding = async () => {
      try {
        let res = await onBoardHostComplete(data);
        if (res?.status === 200) {
          window.location.href = "/user/welcome";
        }
      } catch (err) {
        console.error("Error Occured");
      }
    };
    completeOnBoarding();
  }, []);

  return (
    <>
      <PurebrandLoader />
    </>
  );
};

export default StripeComplete;
