import React, { useContext, useState } from "react";
import Loginimage from "./Loginimage";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthContext from "context/AuthContext";
import { Alert } from "react-bootstrap";
import "./login.css";
import { useEffectOnce } from "hooks/useEffectOnce";
import { passwordRegExp } from "Pages/Auth/signup/Host/SignupForm";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  password: Yup.string()
    .matches(
      passwordRegExp,
      "Must have 8 characters at least with number and a special character"
    )
    .required("Password is required"),
});

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let { loginUser, loginErrors, loggedIn, updateProfile } =
    useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search);
  const [password, setPassword] = useState(true);
  // useEffectOnce(() => {
  //   const timer = setTimeout(() => {
  //     navigate(location.pathname, {});
  //   }, 10000);
  //   // reload and pass empty object to clear state
  //   // we can also use replace option: ..., {replace: true}
  //   return () => clearTimeout(timer);
  // });

  // TODO: shopify api needs to be called once it is updated and ready

  // const shopifyPing = async (data) => {
  //   try {
  //     await updateShopifySignup(
  //       `?customer_email=${data.email}&shop_id=10984882235||67808133441`
  //     );
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  if (loggedIn) {
    return <Navigate to="/user/welcome" />;
  } else {
    return (
      <>
        <div className="container mt-5 mb-5 login-wrapper">
          <div className="row login-column-wrap">
            <div className="col-md-7 col-sm-12  justify-content-center">
              <div className="right-text">
                <h1>Sign in to Purebrand.</h1>
                <p className="paragraph">Your virtual product showroom.</p>
              </div>

              <Loginimage />
            </div>
            <div className="col-md-5 col-sm-12">
              <Formik
                initialValues={{
                  email: location?.state?.email ? location.state.email : "",
                  password: "",
                }}
                onSubmit={async (data, { setSubmitting }) => {
                  try {
                    let res = await loginUser(data);
                    if (res.data.data.is_soft_host !== null) {
                      navigate("/user/set-password");
                    } else {
                      try {
                        if (await updateProfile()) {
                          navigate(
                            queryParams.get("returnUrl")
                              ? queryParams.get("returnUrl")
                              : "/user/welcome"
                          );
                          setSubmitting(false);
                        }
                      } catch (error) {
                        setSubmitting(false);
                      }
                    }
                  } catch (error) {
                    setSubmitting(false);
                  }
                }}
                validationSchema={LoginSchema}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form>
                    {loginErrors ? (
                      <Alert className="" key="danger" variant="danger">
                        {loginErrors.message}
                      </Alert>
                    ) : (
                      <Alert
                        className=""
                        key={
                          location?.state?.changedPassword
                            ? location.state.variant
                            : " "
                        }
                        variant={
                          location?.state?.changedPassword
                            ? location.state.variant
                            : " "
                        }
                      >
                        {location?.state?.changedPassword
                          ? location.state.changedPassword
                          : ""}
                      </Alert>
                    )}

                    <div className="form-outline mb-4">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className={`form-control ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <div className="password-enter-wrapper">
                        <Field
                          type={`${password ? "password" : "text"}`}
                          name="password"
                          placeholder="Enter password"
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                        <div
                          className="password-visibility"
                          onClick={() => setPassword(!password)}
                        >
                          {password ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                            >
                              <path d="M2.03381935,11.8192361 C4.16808919,6.31506648 10.3602678,3.58322297 15.8644374,5.71749281 C18.6654288,6.80359152 20.8800819,9.01824467 21.9661807,11.8192361 C22.0112731,11.9355272 22.0112731,12.0644728 21.9661807,12.1807639 C19.8319108,17.6849335 13.6397322,20.416777 8.1355626,18.2825072 C5.3345712,17.1964085 3.11991805,14.9817553 2.03381935,12.1807639 C1.98872688,12.0644728 1.98872688,11.9355272 2.03381935,11.8192361 Z M8.49709046,17.3501459 C13.4256883,19.2612348 18.9628618,16.8680219 20.9616531,12.0000475 C19.9532865,9.54665483 17.9813179,7.6108696 15.5029095,6.64985412 C10.5743117,4.73876518 5.0371382,7.13197809 3.03834692,11.9999525 C4.04671349,14.4533452 6.01868212,16.3891304 8.49709046,17.3501459 Z M12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" />
                            </svg>
                          ) : (
                            <svg
                              fill="none"
                              height="38"
                              stroke-width="1.5"
                              viewBox="0 0 38 38"
                              width="38"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 3L21 21"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.5 10.6771C10.1888 11.0296 10 11.4928 10 12C10 13.1045 10.8954 14 12 14C12.5072 14 12.9703 13.8112 13.3229 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.36185 7.5611C5.68002 8.73968 4.27894 10.4188 3 12C4.88856 14.991 8.2817 18 12 18C13.5499 18 15.0434 17.4772 16.3949 16.6508"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 6C16.0084 6 18.7015 9.1582 21 12C20.6815 12.5043 20.3203 13.0092 19.922 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>

                    <p
                      onClick={() => navigate("/forgot-password")}
                      className="link-color d-flex justify-content-end"
                    >
                      Forgot password?
                    </p>

                    <div className="text-center text-lg-start mt-4 pt-2">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Please wait..." : "Login"}
                      </button>

                      <p className="alternative small mt-2 mb-0 ">
                        If you don't have an account, you can{" "}
                        <span
                          onClick={() => navigate("/signup")}
                          className="signup-link"
                        >
                          register here
                        </span>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Login;
