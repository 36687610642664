import AuthContext from "context/AuthContext";
import DataContext from "context/DataContext";
import React, { useContext, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";
import StorePerspectiveTable from "./ProspectiveList";
import "./hostlisting.css";
import CustomModal from "components/Modal/CustomModal";

const HostProspectiveList = () => {
  let { userProfile } = useContext(AuthContext);
  let {
    shopifyEmaillistAllSendMail,
    shopifyEmaillistAll,
    prospectiveHost,
    prevProspective,
    nextProspective,
  } = useContext(DataContext);
  const [loadingPros, setLoadingPros] = useState(false);
  const [sortStatus, setSortStatus] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [sendMailSucess, setSendMailSucess] = useState(false);

  const handleClose = () => {
    setSendMail(false);
    setSendMailSucess(false);
  };

  const sendCompletionMail = () => {
    return (
      <>
        <div>
          Send Mail to All Prosprctive Host for completing the SignUp and become
          host.
        </div>
        <div
          className=" btn btn-primary m-3 "
          onClick={async () => {
            setLoadingPros(true);
            try {
              let res = await shopifyEmaillistAllSendMail(
                userProfile?.store?.store_id,
                "?order=true"
              );
              if (res.status === 200) {
                setLoadingPros(false);
                setSendMail(false);
                setSendMailSucess(true);
              }
            } catch (error) {
              setLoadingPros(true);
            }
          }}
        >
          Send Mail
        </div>
      </>
    );
  };

  const getProspective = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (prospectiveHost) {
        if (prospectiveHost?.length < 1) {
          return (
            <tr>
              <td>There aren't any prospective hosts yet!</td>
            </tr>
          );
        } else {
          return prospectiveHost?.map((prospectiveHost, index) => {
            return (
              <StorePerspectiveTable
                key={index}
                prospectives={prospectiveHost}
              />
            );
          });
        }
      } else {
        return <Spinner animation="grow" />;
      }
    }
  };

  return (
    <div>
      <div className="text-end">

        {/* TODO: Temporarily comment the send signup completion mail button */}
        {/* <div
          className=" btn btn-primary bg-secondary m-3 "
          onClick={() => {
            setSendMail(true);
          }}
          hidden={user.role === 5}
        >
          Send signup completion Mail
        </div> */}
      </div>
      <CustomModal
        showStatus={sendMail}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title="Send Mail to become host"
        message={sendCompletionMail()}
        secondButton={false}
        secondMessage="none"
      />
      <CustomModal
        showStatus={sendMailSucess}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title="Sucessfully Sent Mail"
        message={
          "Invitation Mail to all prospective hosts has been sent successfully."
        }
        secondButton={false}
        secondMessage="none"
      />
      <Table className="table-store" responsive>
        <thead>
          <tr>
            <th scope="col">
              <div className="arrows">
                Host{" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = shopifyEmaillistAll(
                      userProfile?.store?.store_id,
                      `?host=${sortStatus}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="arrows">
                Created At{" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = shopifyEmaillistAll(
                      userProfile?.store?.store_id,
                      `?order=${sortStatus}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="arrows">
                Email{" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = shopifyEmaillistAll(
                      userProfile?.store?.store_id,
                      `?email=${sortStatus}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="arrows">Social </div>
            </th>
            <th scope="col">
              <div className="arrows">
                Setup (%){" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = shopifyEmaillistAll(
                      userProfile?.store?.store_id,
                      `?percentage=${sortStatus}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>{getProspective()}</tbody>
      </Table>
      {(nextProspective !== null || prevProspective !== null) && (
        <div className="button-wrapper-product text-center my-4">
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = shopifyEmaillistAll(
                userProfile?.store?.store_id,
                `${new URL(prevProspective).search}`
              );
              res.then(() => setLoadingPros(false));
            }}
            className="btn btn-outline-primary previous-button"
            disabled={prevProspective === null}
          >
            Prev
          </button>
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = shopifyEmaillistAll(
                userProfile?.store?.store_id,
                `${new URL(nextProspective).search}`
              );
              res.then(() => setLoadingPros(false));
            }}
            className="btn btn-outline-primary previous-button"
            disabled={nextProspective === null}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default HostProspectiveList;
