import { CSVData } from "Services/csvService";
import {
  emailListShopify
} from "Services/externalApiService";
import {
  editSoftReviewStore,
  getCommissionAdmin,
  getPublicSoftReview,
  getSoftReviewStore,
  getProductListStore,
  updateProductTag,
  getProductListTagStore,
  getStorePerspectiveUsers,
  sendStorePerspectiveUsersMail,
  postCommissionAdmin,
  postSoftReviewStore,
  addProductListTagStore,
  updateProductListTagStore,
  removeProductListTagStore,
  addRoleByStoreAdmin,
  getRoleByStoreAdmin,
  completeAddRoleByStoreAdmin,
  reinviteRoleByStoreAdmin,
  updateRoleByStoreAdmin,
  downloadMedias,
} from "Services/storeService";
import { demoUserPublicProfile } from "Services/userService";

const { createContext, useState } = require("react");

const DataContext = createContext();
export default DataContext;

export const DataProvider = ({ children }) => {
  const [CSVArrayData, setCSVArrayData] = useState([]);
  const [prospectiveHost, setProspectiveHost] = useState(null);
  const [commissionData, setCommissionData] = useState(null);
  const [nextProspective, setNextProspective] = useState(null);
  const [prevProspective, setPrevProspective] = useState(null);
  const [shopifyEmailList, setShopifyEmailList] = useState(null);
  const [shopifyEmailListMail, setShopifyEmailListMail] = useState(null);
  const [productListAll, setProductListAll] = useState();
  const [productListAllPrev, setProductListAllPrev] = useState(null);
  const [productListAllNext, setProductListAllNext] = useState(null);
  const [productListAllTag, setProductListAllTag] = useState();
  const [productListAllTagPrev, setProductListAllTagPrev] = useState(null);
  const [productListAllTagNext, setProductListAllTagNext] = useState(null);
  const [countProspective, setcountProspective] = useState(0);
  const [countadminList, setcountAdminList] = useState();
  const [allAdminList, setAllAdminList] = useState();
  const [nextAdminList, setNextAdminList] = useState(null);
  const [prevAdminList, setPrevAdminList] = useState(null);

  let csvDataArray = async (params) => {
    try {
      const res = await CSVData(params);
      setCSVArrayData(res.data.data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let shopifyEmaillistAll = async (shopId, param) => {
    try {
      if (shopifyEmailList) {
        try {
          const response = await getStorePerspectiveUsers(
            {
              host_emails: shopifyEmailList.slice(0, 4),
              store_identifier: parseInt(shopId),
            },
            param
          );
          setNextProspective(response.data.data.next);
          setPrevProspective(response.data.data.previous);
          setProspectiveHost(response.data.data.results);
          setcountProspective(response.data.data.count);
          return response;
        } catch (error) {
          throw error;
        }
      } else {
        const res = await emailListShopify(shopId);
          if (res) {
            setShopifyEmailList(res.data.data);
            let body = {
              host_emails: res.data.data.slice(0, 4),
              store_identifier: parseInt(shopId),
            };
            try {
              const response = await getStorePerspectiveUsers(body, param);
              setNextProspective(response.data.data.next);
              setPrevProspective(response.data.data.previous);
              setProspectiveHost(response.data.data.results);
              setcountProspective(response.data.data.count);
              return response;
            } catch (error) {
              throw error;
            }
          }
      }
    } catch (error) {
      throw error;
    }
  };

  let shopifyEmaillistAllSendMail = async (shopId, param) => {
    try {
      if (shopifyEmailListMail) {
        try {
          const response = await sendStorePerspectiveUsersMail(
            {
              host_emails: shopifyEmailListMail.slice(0, 4),
              store_identifier: parseInt(shopId),
            },
            param
          );
          return response;
        } catch (error) {
          throw error;
        }
      } else {
        const res = await emailListShopify(shopId);
        if (res) {
          setShopifyEmailListMail(res.data.data);
          let body = {
            host_emails: res.data.data.slice(0, 4),
            store_identifier: parseInt(shopId),
          };
          try {
            const response = await sendStorePerspectiveUsersMail(body, param);
            return response;
          } catch (error) {
            throw error;
          }
        }
      }
    } catch (error) {
      throw error;
    }
  };
  let adminCommission = async () => {
    try {
      const res = await getCommissionAdmin();
      setCommissionData(res.data.data.results);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let adminCommissionNew = async (data) => {
    try {
      const res = await postCommissionAdmin(data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let getPublicUserProfile = async (id) => {
    try {
      const res = await demoUserPublicProfile(id);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let softReviews = async (body, param, type) => {
    if (type === 1) {
      try {
        const res = await getSoftReviewStore(param);
        return res;
      } catch (error) {
        throw error;
      }
    } else if (type === 2) {
      try {
        const res = await postSoftReviewStore(body);
        return res;
      } catch (error) {
        throw error;
      }
    } else if (type === 3) {
      try {
        const res = await getPublicSoftReview(param);
        return res;
      } catch (error) {
        throw error;
      }
    } else if (type === 4) {
      try {
        const res = editSoftReviewStore(body, param);
        return res;
      } catch (error) {
        throw error;
      }
    }
  };

  let storeAdminProductList = async (param) => {
    try {
      const res = await getProductListStore(param);
      setProductListAll(res?.data?.results);
      setProductListAllPrev(res?.data?.previous);
      setProductListAllNext(res?.data?.next);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updateProductTagStore = async (id, data) => {
    try {
      const res = await updateProductTag(id, data);

      return res;
    } catch (error) {
      throw error;
    }
  };

  let storeAdminProductListTag = async (params) => {
    try {
      const res = await getProductListTagStore(params);
      setProductListAllTag(res?.data?.results);
      setProductListAllTagPrev(res?.data?.previous);
      setProductListAllTagNext(res?.data?.next);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let setStoreAdminProductListTag = async (data) => {
    try {
      const res = await addProductListTagStore(data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updateStoreAdminProductListTag = async (id, data) => {
    try {
      const res = await updateProductListTagStore(id, data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let deleteStoreAdminProductListTag = async (id) => {
    try {
      const res = await removeProductListTagStore(id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let getAssignedRole = async (params) => {
    try {
      const res = await getRoleByStoreAdmin(params);
      setAllAdminList(res?.data?.results?.users);
      setNextAdminList(res?.data?.next);
      setPrevAdminList(res?.data?.previous);
      setcountAdminList(res?.data?.users?.length);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let assignNewRole = async (data) => {
    try {
      const res = await addRoleByStoreAdmin(data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updateAssignedRole = async (role, id) => {
    const roleId = { role: role };
    try {
      const res = await updateRoleByStoreAdmin(roleId, id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let reAssignNewRole = async (uid) => {
    try {
      const res = await reinviteRoleByStoreAdmin(uid);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let completeAssignNewRole = async (value) => {
    const userId = value.uid;
    const userToken = value.userToken;
    const body = {
      new_password: value.password,
      confirm_password: value.confirm_password,
    };
    try {
      const res = await completeAddRoleByStoreAdmin(body, userId, userToken);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let getAllMediaDownload = async () => {
    try {
      const res = await downloadMedias();
      return res;
    } catch (error) {
      throw error;
    }
  };

  // useEffectOnce(() => {
  //   if (user?.role === 2 && loggedIn) {
  //     shopifyEmaillistAll(userProfile?.store?.store_id, "?order=true");
  //   }
  // }, [loggedIn]);

  let contextData = {
    csvDataArray: csvDataArray,
    CSVArrayData: CSVArrayData,
    shopifyEmaillistAll: shopifyEmaillistAll,
    shopifyEmaillistAllSendMail: shopifyEmaillistAllSendMail,
    prospectiveHost: prospectiveHost,
    adminCommission: adminCommission,
    commissionData: commissionData,
    adminCommissionNew: adminCommissionNew,
    nextProspective: nextProspective,
    prevProspective: prevProspective,
    countProspective: countProspective,
    getPublicUserProfile: getPublicUserProfile,
    softReviews: softReviews,
    storeAdminProductList: storeAdminProductList,
    productListAllPrev: productListAllPrev,
    productListAllNext: productListAllNext,
    productListAll: productListAll,
    updateProductTagStore: updateProductTagStore,
    storeAdminProductListTag: storeAdminProductListTag,
    productListAllTagPrev: productListAllTagPrev,
    productListAllTagNext: productListAllTagNext,
    productListAllTag: productListAllTag,
    setStoreAdminProductListTag: setStoreAdminProductListTag,
    updateStoreAdminProductListTag: updateStoreAdminProductListTag,
    deleteStoreAdminProductListTag: deleteStoreAdminProductListTag,
    assignNewRole: assignNewRole,
    getAssignedRole: getAssignedRole,
    allAdminList: allAdminList,
    prevAdminList: prevAdminList,
    nextAdminList: nextAdminList,
    completeAssignNewRole: completeAssignNewRole,
    reAssignNewRole: reAssignNewRole,
    updateAssignedRole: updateAssignedRole,
    getAllMediaDownload: getAllMediaDownload,
  };
  return (
    <DataContext.Provider value={contextData}>{children}</DataContext.Provider>
  );
};
