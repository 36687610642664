import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import UpdatePaymentForm from "./UpdatePaymentForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_ADD_STRIPE_KEY}`);
// console.log(process.env.REACT_APP_ADD_STRIPE_KEY);

const UpdateBankForm = ({
  sureShow,
  setSureShow,
  showBankFormUpdate,
  setShowBankFormUpdate,
}) => {
  return (
    <div className="App">
      {/* {clientSecret && ( */}
      <Elements stripe={stripePromise}>
        <UpdatePaymentForm
          sureShow={sureShow}
          setSureShow={setSureShow}
          showBankFormUpdate={showBankFormUpdate}
          setShowBankFormUpdate={setShowBankFormUpdate}
        />
      </Elements>
      {/* )} */}
    </div>
  );
};
export default UpdateBankForm;
