import AuthContext from "context/AuthContext";
import React, { useState, useRef, useContext } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";

const JoinasHost = () => {
  const { userProfile } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#359CD7");
  const target = useRef(null);
  const currentUrl = window.location;

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const setUrl = `https://${currentUrl?.host}/signup?shop=${userProfile.store.store_id}`;

  const generateDynamicCode = () => {
    const htmlCode = ` 
    <a href=${setUrl}>
    <button class="join-as-button" id="join-as-button"
    style=" width:300px; height:50px' border:none; letter-spacing:0.02em; 
    font-weight:'400'; font-size:'18px'; background:${backgroundColor};color:#fffff; border-radius:5px;" >
    Accept Invitation
    </button>
    </a>
    `;
    return htmlCode;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generateDynamicCode());
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };

  return (
    <div>
      <div className="code-snippet-codes mb-3 mt-3">
        <code
          style={{
            display: "block",
            background: "#000000",
            borderRadius: "12px",
          }}
          className="snippet_code"
        >
          <div className="code-title">
            <p className="code-language">HTML </p>
            <button
              className="copy-button"
              ref={target}
              onClick={handleCopyClick}
            >
              <Clipboard size={20} color="#fff" />
            </button>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Copied!
                </Tooltip>
              )}
            </Overlay>
          </div>
          <div className="code-text">
            <pre>{generateDynamicCode()}</pre>
          </div>
        </code>
      </div>
      <div className="row py-5">
        <div className="col-sm-12 col-md-12 ">
          <div className="color">
            <h6>Set Button Color </h6>
            <form onSubmit={handleColorChange} className="form-star">
              <input
                type="color"
                value={backgroundColor}
                className="form-control-color"
                style={{ background: "none", borderColor: "#ccc" }}
                onChange={handleColorChange}
              />
            </form>
          </div>
        </div>
      </div>

      <hr />
      <h5>Preview </h5>
      <a href={setUrl}>
        <button
          className="join-as-button btn"
          id="schedule-demo-button"
          style={{
            width: "auto",
            minWidth: "200px",
            height: "50px",
            letterSpacing: "0.02em",
            fontWeight: "500",
            fontSize: "18px",
            color: "#fff",
            background: `${backgroundColor}`,
            borderRadius: "5px",
          }}
        >
          Accept Invitation
        </button>
      </a>
    </div>
  );
};

export default JoinasHost;
