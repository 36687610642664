import CustomModal from "components/Modal/CustomModal";
import PaymentContext from "context/PaymentContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import { Alert, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentStoreList from "../Payments/PaymentStoreList";
import * as moment from "moment";
import "../storedashboard.css";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import axios from "axios";

const StoreBills = () => {
  const { storeDueAmount } = useContext(PaymentContext);
  const [paymentData, setPaymentData] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const getDueAmount = () => {
    if (paymentData && paymentData.length > 0) {
      return paymentData[0]?.store.company.due_amount;
    } else {
      return 0.0;
    }
  };

  const getPaymentIds = () => {
    if (paymentData) {
      let paymentIds = [];
      paymentData.map((data) => paymentIds.push(data.id));
      return paymentIds;
    } else {
      return [];
    }
  };

  const handlePaypalClick = async () => {
    let res = await axios.get(
      `https://www.paypal.com/connect?flowEntry=static&client_id=${process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD}&response_type=id_token&scope=openid&redirect_uri=https://purebrand.io/user/accounting&state=redirected`
    );
    if (res) {
      console.log(res);
    }
  };
  // useEffectOnce(() => {
  //   const timer = setTimeout(() => {
  //     navigate(location.pathname, {});
  //   }, 10000);
  //   // reload and pass empty object to clear state
  //   // we can also use replace option: ..., {replace: true}
  //   return () => clearTimeout(timer);
  // });
  useEffectOnce(() => {
    // const res = storeDueAmount();
    // res
    //   .then(function (response) {
    //     setPaymentData(response.data.data.results);
    //     setLoading(false);
    //     // console.log(response.data.data.results);
    //   })
    //   .catch(function (error) {
    //     setError(error);
    //     setLoading(false);
    //   });
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <PurebrandLoader />
      ) : (
        <div className="billing-page-wrapper">
          <div className="row billing-row-first">
            {/* <div className="col-md-12 col-lg-4 text-center">
              <div className="features d-block store-liable-payment-wrapper">
                {location?.state?.paymentCheck ? (
                  <Alert variant="success">
                    {location?.state.paymentCheck}
                  </Alert>
                ) : (
                  <></>
                )}
                <p>You have ${getDueAmount()} to pay.</p>

                <div className="paypal.checkout mt-3">
                  <>
                    <p className="detail_paument" onClick={handleShow}>
                      View details
                    </p>
                    <CustomModal
                      showStatus={show}
                      clickStatus={handleClose}
                      buttonMessage="Close"
                      closeStatus={handleClose}
                      title="Payment Detail"
                      message={<StoreBreakdownPayment details={paymentData} />}
                      secondMessage="none"
                      secondButton={false}
                    />
                    // comment from here
                    <button
                      className="btn btn-primary btn-primary-sm"
                      onClick={() =>
                        navigate("/user/checkout", {
                          state: {
                            amount: getDueAmount(),
                            type: "storeBills",
                            quantity: 1,
                            redirected: true,
                            details: getPaymentIds(),
                            store_id: paymentData[0]?.store.id,
                          },
                        })
                      }
                    >
                      Proceed to pay
                    </button>
                    // comment upto here
                  </>
                  // comment from here
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={handlePaypalClick}
                    >
                      PaypalPay
                    </button>
                  </div>
                  // comment upto here
                </div>
              </div>
              // comment from here
              <div className="features d-block mt-3 mb-3 store-get-more-review">
                <p>You have 5 reject review left.</p>
                <p>
                  Get more{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    here
                  </span>
                </p>
              </div>{" "}
              // comment upto here
            </div> */}

            <div className="col-md-12 col-lg-12">
              <div className="features payout-list-for-store">
                <PaymentStoreList />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StoreBills;

// const StoreBreakdownPayment = ({ details }) => {
//   const getPaymentTable = () => {
//     if (details) {
//       return details?.map((payment) => {
//         return (
//           <>
//             {payment.earning_paid ? (
//               <tr key={payment.id}>
//                 {payment.earning_paid_store ? (
//                   <></>
//                 ) : (
//                   <>
//                     <td>
//                       {moment(payment?.earning_paid).format("MMMM Do YYYY")}
//                     </td>
//                     <td>
//                       {payment.user.first_name}{" "}
//                       {/* {payment.user.last_name.charAt(0)}. */}
//                     </td>
//                     <td>Demo Comission</td>
//                     <td>${payment.earning}</td>
//                     <td>${payment.pure_brand_earning_commission}</td>
//                   </>
//                 )}
//               </tr>
//             ) : (
//               <></>
//             )}
//             {payment.sales_commission_paid ? (
//               <tr>
//                 {payment.sales_commission_paid_store ? (
//                   <></>
//                 ) : (
//                   <>
//                     <td>
//                       {moment(payment?.sales_commission_paid).format(
//                         "MMMM Do YYYY"
//                       )}
//                     </td>
//                     <td>
//                       {payment.user.first_name}{" "}
//                       {/* {payment.user.last_name.charAt(0)}. */}
//                     </td>
//                     <td>Sales Comission</td>
//                     <td>${payment.sales_commission}</td>
//                     <td>${payment.pure_brand_sales_commission}</td>
//                   </>
//                 )}
//               </tr>
//             ) : (
//               <></>
//             )}
//           </>
//         );
//       });
//     }
//   };

//   return (
//     <div>
//       {details ? (
//         <Table>
//           <thead>
//             <tr>
//               <th>Date</th>
//               <th>Host</th>
//               <th>Type</th>
//               <th>Amount</th>
//               <th>Transaction Charge</th>
//             </tr>
//           </thead>
//           <tbody>{getPaymentTable()}</tbody>
//         </Table>
//       ) : (
//         <></>
//       )}
//     </div>
//   );
// };
