import Dashelement from "components/Dashboard/Dashelement";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import "../ProfileCompletion/profileform.css";

const schema = yup.object().shape({
  demoCommission: yup
    .number()
    .required("required")
    .test("is-decimal", "invalid decimal", (value) =>
      (value + "").match(/^\d*\.?\d*$/)
    ),
  salesCommission: yup
    .number()
    .required("required")
    .test("is-decimal", "invalid decimal", (value) =>
      (value + "").match(/^\d*\.?\d*$/)
    ),
  discountGiven: yup
    .number()
    .required("required")
    .test("is-decimal", "invalid decimal", (value) =>
      (value + "").match(/^\d*\.?\d*$/)
    ),
});

const StoreDetail = (props) => {
  const handleSubmit = (values) => {
    values.demoCommission = parseFloat(values.demoCommission);
    values.salesCommission = parseFloat(values.salesCommission);
    values.discountGiven = parseFloat(values.discountGiven);
    values.no_show_demo_commission = parseFloat(values.no_show_demo_commission);
    props.next(values);
  };
  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Dashelement className="features basic-info-wrapper">
            <div className="container">
              <div className="information-text-wrapper">
                <h3>Store Information</h3>
                <p>
                  Please enter the following details for your store hosts and
                  guests to receive their shares.
                </p>
              </div>
              <div className="row">
                <div className="col col-md-3">
                  <Field
                    placeholder="Demo commission (Eg. 12.99)"
                    type="text"
                    name="demoCommission"
                    className={`form-control ${
                      touched.demoCommission && errors.demoCommission
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="demoCommission"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col col-md-1 select-type-percent w-25">
                  <Field
                    as="select"
                    name="commissionType"
                    defaultValue="AMOUNT"
                    className={`form-control ${
                      touched.commissionType && errors.commissionType
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option defaultValue={"AMOUNT"} value="AMOUNT">
                      $
                    </option>
                    <option value={"PERCENTAGE"}>%</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="commissionType"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col col-md-3">
                  <Field
                    placeholder="Sales commission (Eg. 12.99)"
                    type="text"
                    name="salesCommission"
                    className={`form-control ${
                      touched.salesCommission && errors.salesCommission
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="salesCommission"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col col-md-1 select-type-percent w-25">
                  <Field
                    as="select"
                    defaultValue="AMOUNT"
                    name="salesCommissionType"
                    className={`form-control ${
                      touched.salesCommissionType && errors.salesCommissionType
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option defaultValue={"AMOUNT"} value="AMOUNT">
                      $
                    </option>
                    <option value={"PERCENTAGE"}>%</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="salesCommissionType"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col col-md-3">
                  <Field
                    placeholder="No show commission (Eg. 12.99)"
                    type="text"
                    name="no_show_demo_commission"
                    className={`form-control ${
                      touched.no_show_demo_commission &&
                      errors.no_show_demo_commission
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="no_show_demo_commission"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col col-md-1 select-type-percent w-25">
                  <Field
                    as="select"
                    defaultValue="AMOUNT"
                    name="no_show_demo_commission_type"
                    className={`form-control ${
                      touched.no_show_demo_commission_type &&
                      errors.no_show_demo_commission_type
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option defaultValue={"AMOUNT"} value="AMOUNT">
                      $
                    </option>
                    <option value={"PERCENTAGE"}>%</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="no_show_demo_commission_type"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col col-md-3">
                  <Field
                    placeholder="Discount (Eg. 12.99)"
                    type="text"
                    name="discountGiven"
                    className={`form-control ${
                      touched.discountGiven && errors.discountGiven
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="discountGiven"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col col-md-1 select-type-percent w-25">
                  <Field
                    as="select"
                    name="discountType"
                    defaultValue="AMOUNT"
                    className={`form-control ${
                      touched.discountType && errors.discountType
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option defaultValue={"AMOUNT"} value="AMOUNT">
                      $
                    </option>
                    <option value={"PERCENTAGE"}>%</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="discountType"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              {/* <div className="row mt-4">
                  <div className="col col-md-4">
                    <label>Select the day for payout release</label>
                  </div>
                  <div className="col col-md-3">
                    <Field
                      as="select"
                      name="paymentFrequency"
                      defaultValue={0}
                      className={`form-control ${
                        touched.paymentFrequency && errors.paymentFrequency
                          ? "is-invalid"
                          : ""
                      }`}
                    >
                      <option defaultValue={0} value={0}>
                        Monday
                      </option>
                      <option value={1}>Tuesday</option>
                      <option value={2}>Wednesday</option>
                      <option value={3}>Thursday</option>
                      <option value={4}>Friday</option>
                      <option value={5}>Saturday</option>
                      <option value={6}>Sunday</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="paymentFrequency"
                      className="invalid-feedback"
                    />
                  </div>
                </div> */}
            </div>
          </Dashelement>
          <div className="button-place text-end">
            <button className="btn btn-primary" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StoreDetail;
