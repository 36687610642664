import CustomModal from "components/Modal/CustomModal";
import AuthContext from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TelephoneXFill } from "react-bootstrap-icons";

export default function EndCallIcon({ handleEndCall, demoInfo }) {
  const { user, loggedIn } = useContext(AuthContext);
  const [sureEnd, setSureEnd] = useState(false);
  const handleClose = () => {
    setSureEnd(false);
  };

  const startTime = new Date(demoInfo?.started_at);
  const scheduleTime = new Date(
    `${demoInfo?.schedule?.scheduled_date}T${demoInfo?.schedule?.scheduled_time}Z`
  );  
  let minStayTime;

  if (scheduleTime <= startTime || demoInfo?.soonest_at) {
    minStayTime = new Date(startTime.getTime() + 10 * 60 * 1000);
  } else {
    minStayTime = new Date(scheduleTime.getTime() + 10 * 60 * 1000);
  }

  const nowTime = new Date();
  const [countdown, setCountdown] = useState(calculateCountdown(minStayTime));

  useEffect(() => {
    if (minStayTime > nowTime) {
      const intervalId = setInterval(() => {
        setCountdown(calculateCountdown(minStayTime));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [minStayTime]);

  // countdown time, adds 10 minutes to demo start time and then countdowns
  function calculateCountdown(targetTime) {
    const now = new Date();
    const difference = targetTime.getTime() - now.getTime();
    const seconds = Math.floor(difference / 1000);

    return {
      minutes: Math.floor(seconds / 60),
      seconds: seconds % 60,
    };
  }

  return (
    <>
      <CustomModal
        showStatus={sureEnd}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title="Sure End Call"
        message={
          <>
            <p>Are you sure you want to end the meeting?</p>
            <button onClick={handleEndCall} className="btn btn-danger ">
              End Call
            </button>
          </>
        }
        secondButton={false}
        secondMessage="none"
      />

      {demoInfo?.members?.guest_detail?.attend_meeting === null ? (
        <>
          {user && loggedIn && (
            <>
              <OverlayTrigger
                key={"top"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>
                    {minStayTime > nowTime ? (
                      <>
                        You must stay in call until {countdown.minutes} minutes
                        and {countdown.seconds} seconds to get comission.
                      </>
                    ) : (
                      "You can now end the call"
                    )}
                  </Tooltip>
                }
              >
                <button
                  onClick={() => {
                    setSureEnd(true);
                  }}
                  style={{ background: "#FF3030" }}
                  className="round-primary-btn "
                >
                  <TelephoneXFill />
                </button>
              </OverlayTrigger>
            </>
          )}
        </>
      ) : (
        <button
          onClick={() => {
            setSureEnd(true);
          }}
          style={{ background: "#FF3030" }}
          className="round-primary-btn "
        >
          <TelephoneXFill />
        </button>
      )}
    </>
  );
}
