import PurebrandLoader from "components/Loader/PurebrandLoader";
import CustomModal from "components/Modal/CustomModal";
import AuthContext from "context/AuthContext";
import PaymentContext from "context/PaymentContext";
import React, { useContext, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { utcLocalTimeStringConversion } from "utils/dateFormatter";
import "./paymentadmin.css";

const PaymentListUser = (props) => {
  const { user } = useContext(AuthContext);
  const { payToHostlist, payToHostUpdate, payToHostlistPaid } =
    useContext(PaymentContext);

  const [loading, setLoading] = useState(false);
  const [responseMsgPopup, setResponseMsgPopup] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const handleCloseResponse = () => setResponseMsgPopup(false);

  const handleUnpaidPayment = (status, message, type, stats) => {
    setLoading(true);
    const data = {
      status: status,
      message: message,
      type: type,
      payment_status: stats,
    };
    const res = payToHostUpdate(data, props?.hostDetails.id);
    res
      .then(function (response) {
        setLoading(true);
        payToHostlist("?order=true&paid=false");
        payToHostlistPaid("?order=true&paid=true");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let popoverClickRootClose = (
    <Popover id="popover-trigger-click-close" style={{ zIndex: 10000 }}>
      <div>
        <h4> Are you sure?</h4>
        <br />
        <h6>Mark Payment complete </h6>
      </div>
      <div className="button-payment-wrapper">
        <button
          className="btn btn-primary btn-sm-primary btn-paid"
          onClick={() => {
            handleUnpaidPayment("200", "success", "DEMO", "DONE");
            setResponseMsgPopup(true);
            setResponseMsg("Amount has been paid");
          }}
          disabled={
            !props?.hostDetails?.members?.host_detail?.has_completed_onboarding
          }
        >
          Pay
        </button>
        <button
          className="btn btn-primary btn-sm-primary btn-failed"
          onClick={() => {
            handleUnpaidPayment("400", "Failed", "DEMO", "FAILED");
            setResponseMsgPopup(true);
            setResponseMsg("Canceled payment");
          }}
        >
          Cancel
        </button>
        {!props?.hostDetails?.members?.host_detail
          ?.has_completed_onboarding && (
          <>
            <span className="text-danger">
              ** This Host hasn't completed their onboarding.
            </span>
          </>
        )}
      </div>
    </Popover>
  );

  let popoverClickRootCloseSales = (
    <Popover id="popover-trigger-click-close" style={{ zIndex: 10000 }}>
      <div>
        <h4> Are you sure?</h4>
        <br />
        <h6>Mark Payment complete</h6>
      </div>
      <div className="button-payment-wrapper">
        <button
          className="btn btn-primary btn-sm-primary btn-paid"
          onClick={() => {
            handleUnpaidPayment("200", "success", "COMMISSION", "DONE");
            setResponseMsgPopup(true);
            setResponseMsg("Amount has been paid");
          }}
          disabled={
            !props?.hostDetails?.members?.host_detail?.has_completed_onboarding
          }
        >
          Pay
        </button>
        <button
          className="btn btn-primary btn-sm-primary btn-failed"
          onClick={() => {
            handleUnpaidPayment("400", "Failed", "COMMISSION", "FAILED");
            setResponseMsgPopup(true);
            setResponseMsg("Canceled payment");
          }}
        >
          Cancel
        </button>
        {!props?.hostDetails?.members?.host_detail
          ?.has_completed_onboarding && (
          <>
            <span className="text-danger">
              ** This Host hasn't completed their onboarding.
            </span>
          </>
        )}
      </div>
    </Popover>
  );
  if (loading === true) {
    return <PurebrandLoader />;
  }

  const FailedStatus = [
    "card_declined",
    "payment_blocked",
    "expired_card",
    "error_creating_intent",
    "payout_failed",
    "failed"
  ];

  return (
    <>
      {props?.hostDetails.earning.earning_paid !== null ? (
        <></>
      ) : (
        <>
          {props?.hostDetails?.earning?.earning_paid === null &&
          FailedStatus.some((status) =>
            props?.hostDetails?.earning?.stripe_payment_status.includes(status) || props?.hostDetails?.earning?.dots_payment_status.includes(status)
          ) ? (
            <></>
          ) : (
            <>
              <tr>
                <td>
                  {props?.hostDetails?.members?.host_detail?.first_name}{" "}
                  {(props?.hostDetails?.members?.host_detail?.last_name).charAt(
                    0
                  )}
                  .
                </td>
                <td>{props?.hostDetails?.product?.store?.name}</td>
                <td>{props?.hostDetails?.product?.name}</td>
                <td>Demo Comission</td>
                <td>{props?.hostDetails?.earning?.earning}</td>
                {/* <td>
            {user.role === 1 || 2 ? (
              <OverlayTrigger
                id="help"
                trigger={"click"}
                rootClose
                placement="top"
                overlay={popoverClickRootClose}
              >
                <button
                  className=" btn admin-payment-popup bg-info text-center text-white px-3 py-0"
                  style={{
                    borderRadius: "10px",
                  }}
                  disabled={user.role === 5}
                >
                  Pay with Stripe
                </button>
              </OverlayTrigger>
            ) : (
              <p className="admin-payment-popup">Pending</p>
            )}
          </td> */}
                <td>{props?.hostDetails?.members?.guest_detail?.full_name}</td>
                <td>
                  {utcLocalTimeStringConversion(props?.hostDetails?.ended_at)}
                </td>
              </tr>
            </>
          )}
        </>
      )}

      {props?.hostDetails.earning.sales_commission !== "0.00" &&
      props?.hostDetails.earning.sales_commission_paid !== null ? (
        <></>
      ) : (
        <>
          {props?.hostDetails?.earning?.sales_commission_paid === null &&
          FailedStatus.some((status) =>
            props?.hostDetails?.earning?.stripe_sales_payment_status.includes(
              status
            ) || props?.hostDetails?.earning?.dots_sales_payment_status.includes(
              status
            )
          ) ? (
            <></>
          ) : (
            <>
              <tr>
                <td>
                  {props?.hostDetails?.members?.host_detail?.first_name}{" "}
                  {(props?.hostDetails?.members?.host_detail?.last_name).charAt(
                    0
                  )}
                  .
                </td>
                <td>{props?.hostDetails?.product?.store?.name}</td>
                <td>{props?.hostDetails?.product?.name}</td>
                <td>Sales Comission</td>
                <td>{props?.hostDetails?.earning?.sales_commission}</td>
                {/* <td>
              {user.role === 1 || 2 ? (
                <OverlayTrigger
                  id="help"
                  trigger={"click"}
                  rootClose
                  placement="top"
                  overlay={popoverClickRootCloseSales}
                >
                  <button
                    className=" btn admin-payment-popup bg-info text-center text-white px-3 py-0"
                    style={{
                      borderRadius: "10px",
                    }}
                    disabled={user.role === 5}
                  >
                    Pay with Stripe
                  </button>
                </OverlayTrigger>
              ) : (
                <p className="admin-payment-popup">Pending</p>
              )}
            </td> */}
                <td>{props?.hostDetails?.members?.guest_detail?.full_name}</td>
                <td>
                  {utcLocalTimeStringConversion(props?.hostDetails?.ended_at)}
                </td>
              </tr>
            </>
          )}
        </>
      )}
      {/* <CustomModal
        showStatus={responseMsgPopup}
        title="Confirmation"
        clickStatus={() => {
          handleCloseResponse();
        }}
        buttonMessage="Close"
        message={responseMsg}
        closeStatus={() => {
          handleCloseResponse();
        }}
      /> */}
    </>
  );
};

export default PaymentListUser;
