import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik"; // A custom component for rendering the rating stars
import * as Yup from "yup";
import { Rating } from "react-simple-star-rating";
import { Card, Spinner } from "react-bootstrap";
import { ProductImage } from "assets/images";
import DataContext from "context/DataContext";
import { capitalizeFirst } from "utils/stringFormatter";

const RatingForm = ({
  detPro,
  newClassCustom,
  allReviews,
  doneReviews,
  accessDemoCommission,
  setShowA,
  userStatus,
  setShowB,
}) => {
  const { softReviews } = useContext(DataContext);
  // const [checkedInput, setCheckedInput] = useState(false);
  return (
    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 mb-4">
      <Card className="cardcustom">
        <Card.Img
          className={`image-listing image-fluid image-responsive ${
            newClassCustom ? newClassCustom : ""
          }`}
          variant="top"
          src={
            detPro.product_image === "" ? ProductImage : detPro.product_image
          }
          alt={"ProductImage"}
        />
        <Card.Body>
          <div className="row product-detail-row">
            <div className="col product-col">
              <Card.Title>{detPro.product_name}</Card.Title>
              <Card.Text>{detPro.shop_name}</Card.Text>
            </div>
          </div>
          <div className="pricing-wrapper">
            <Card.Title>${detPro.product_price}</Card.Title>
          </div>
          <Formik
            initialValues={{
              stars: detPro.stars ? parseFloat(detPro.stars) : 0,
              comment: detPro.comment ? detPro.comment : "",
            }}
            validationSchema={Yup.object({
              stars: Yup.number().required("Rating is required"),
              // comment: Yup.string().required("Comment is required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              // Do something with the form data here
              setSubmitting(true);
              let data = {
                stars: values.stars.toFixed(1),
                comment: values.comment,
                soft_product: {
                  order_identifier: detPro.order_id,
                  identifier: detPro.product_id,
                  name: detPro.product_name,
                  handle: detPro.product_handle,
                  image: detPro.product_image,
                  price: detPro.product_price,
                  currency_code: detPro.currency,
                  tags: detPro.product_type
                    ? [
                        {
                          name: detPro.product_type,
                        },
                      ]
                    : [],
                  soft_store: {
                    identifier: detPro.shop_id,
                    name: detPro.shop_name,
                    domain: detPro.shop_domain,
                    email: detPro.email,
                  },
                },
                soft_user: {
                  customer_identifier: detPro.customer_id,
                  first_name: capitalizeFirst(detPro.customer_first_name),
                  last_name: capitalizeFirst(detPro.customer_last_name),
                  email: detPro.customer_email,
                },
              };
              // console.log(data);
              const res = await softReviews(
                detPro.stars
                  ? { stars: values.stars.toFixed(1), comment: values.comment }
                  : data,
                detPro.reviewId ? detPro.reviewId : null,
                detPro.stars ? 4 : 2
              );
              // const res = await softReviews(data, null, 2);
              // console.log("response", res);
              if (res) {
                let first = allReviews();
                let second = doneReviews();
                let third = accessDemoCommission();
                const promise = Promise.all([first, second, third]);
                promise
                  .then(() => {
                    setSubmitting(false);
                    if (userStatus === true) {
                      setShowB(true);
                    } else {
                      setShowA(true);
                    }
                    // console.log(data);
                  })
                  .catch(() => {
                    setSubmitting(false);
                  });
              }
            }}
          >
            {({ isSubmitting, setFieldValue, touched, errors, values }) => (
              <Form>
                <Rating
                  transition
                  allowFraction
                  initialValue={detPro.stars}
                  onClick={(e) => setFieldValue("stars", e)}
                  className={`${
                    touched.rating && errors.rating ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="stars"
                  className="invalid-feedback"
                />
                <Field
                  as="textarea"
                  name="comment"
                  className={`form-control mt-3 ${
                    touched.comment && errors.comment ? "is-invalid" : ""
                  }`}
                  rows={3}
                  placeholder="Enter your review"
                />
                <ErrorMessage
                  component="div"
                  name="comment"
                  className="invalid-feedback"
                />
                {/* <div className="form-group mt-1">
                  <input
                    id="hosting_opportunities_check"
                    type="checkbox"
                    checked={checkedInput}
                    onChange={(e) => setCheckedInput(!checkedInput)}
                  />{" "}
                  <label htmlFor="hosting_opportunities_check">
                    <p>Opt-in to be eligible to host</p>
                  </label>
                </div> */}

                <button
                  type="submit"
                  className="btn rating_final_button mt-3"
                  disabled={isSubmitting || values.stars === 0}
                >
                  {isSubmitting ? <Spinner animation="grow" /> : "Rate"}
                </button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RatingForm;
