import React, { useState, useRef } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";

const ScheduleVideoSnippet = () => {
  const [show, setShow] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#359CD7");
  const [url, setUrl] = useState("/user/soft-reviews");
  const target = useRef(null);
  const [btnText, setBtnText] = useState("Schedule video Demo");

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleBtnTextChange = (e) => {
    setBtnText(e.target.value);
  };

  const generateDynamicCode = () => {
    const htmlCode = ` 
    <a href=${url}>
    <button class="schedule-demo-button" id="schedule-demo-button"
    style=" width:300px; height:50px' border:none; letterSpacing:0.02em; 
    fontWeight:'400'; fontSize:'18px'; background:${backgroundColor};color:#fffff; border-radius:10px;" >
    ${btnText}
    </button>
    </a>
    `;
    return htmlCode;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generateDynamicCode());
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };

  return (
    <div>
      <div className="code-snippet-codes mb-3 mt-3">
        <code
          style={{
            display: "block",
            background: "#000000",
            borderRadius: "12px",
          }}
          className="snippet_code"
        >
          <div className="code-title">
            <p className="code-language">HTML </p>
            <button
              className="copy-button"
              ref={target}
              onClick={handleCopyClick}
            >
              <Clipboard size={20} color="#fff" />
            </button>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  Copied!
                </Tooltip>
              )}
            </Overlay>
          </div>
          <div className="code-text">
            <pre>{generateDynamicCode()}</pre>
          </div>
        </code>
      </div>
      <div className="row py-5">
        <div className="col-sm-12 col-md-5">
          <div className="url">
            <h6>Set Url </h6>
            <input
              className="form-control form-control-lg"
              placeholder="Enter Url"
              type="text"
              defaultValue={url}
              onChange={handleUrlChange}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-2 ">
          <div className="color">
            <h6>Set Color </h6>
            <form onSubmit={handleColorChange} className="form-star">
              <input
                type="color"
                value={backgroundColor}
                className="form-control-color"
                style={{ background: "none", borderColor: "#ccc" }}
                onChange={handleColorChange}
              />
            </form>
          </div>
        </div>
        <div className="col-sm-12 col-md-5">
          <div className="btnText">
            <h6>Set Button Name </h6>
            <form onSubmit={handleColorChange} className="form-star">
              <input
                type="text"
                placeholder="Enter Button Name"
                value={btnText}
                className="form-control form-control-lg"
                style={{ background: "none", borderColor: "#ccc" }}
                onChange={handleBtnTextChange}
              />
            </form>
          </div>
        </div>
      </div>

      <hr />
      <h5>Preview </h5>
      <a href={url}>
        <button
          className="schedule-demo-button btn"
          id="schedule-demo-button"
          style={{
            width: "auto",
            minWidth: "200px",
            height: "50px",
            letterSpacing: "0.02em",
            fontWeight: "500",
            fontSize: "18px",
            color: "#fff",
            background: `${backgroundColor}`,
            borderRadius: "10px",
          }}
        >
          {btnText}
        </button>
      </a>
    </div>
  );
};

export default ScheduleVideoSnippet;
