import React from "react";
import { utcLocalTimeStringConversion } from "utils/dateFormatter";

const PaidListUser = (props) => {
  return (
    <>
      {props?.hostDetails.earning.earning_paid !== null && (
        <tr>
          <td>
            {props?.hostDetails.members.host_detail.first_name}{" "}
            {(props?.hostDetails.members.host_detail.last_name).charAt(0)}.
          </td>
          <td>{props?.hostDetails.product.store.name}</td>
          <td>{props?.hostDetails.product.name}</td>
          <td>Demo Comission</td>
          <td>{props?.hostDetails.earning.earning}</td>
          {/* <td>
            <p className="admin-payment-popup">Paid</p>
          </td> */}
          <td>{props?.hostDetails?.members?.guest_detail?.full_name}</td>
          <td>{utcLocalTimeStringConversion(props?.hostDetails?.ended_at)}</td>
        </tr>
      )}

      {props?.hostDetails.earning.sales_commission !== "0.00" && (
        <>
          {props?.hostDetails.earning.sales_commission_paid !== null && (
            <tr>
              <td>
                {props?.hostDetails.members.host_detail.first_name}{" "}
                {(props?.hostDetails.members.host_detail.last_name).charAt(0)}.
              </td>
              <td>{props?.hostDetails.product.store.name}</td>
              <td>{props?.hostDetails.product.name}</td>
              <td>Sales Comission</td>
              <td>{props?.hostDetails.earning.sales_commission}</td>
              {/* <td>
                <p className="admin-payment-popup">Paid</p>
              </td> */}
              <td>{props?.hostDetails?.members?.guest_detail?.full_name}</td>
              <td>
                {utcLocalTimeStringConversion(props?.hostDetails?.ended_at)}
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default PaidListUser;
