import React, { useContext, useEffect, useState } from "react";
import { base64Decode, base64Encode } from "@firebase/util";
import ApiContext from "context/ApiContext";
import { Field, Form, Formik } from "formik";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import { Alert, Button } from "react-bootstrap";
import { generateSignature } from "utils/dataSecurity";
import AuthContext from "context/AuthContext";
import { capitalizeFirst } from "utils/stringFormatter";
import CustomModal from "components/Modal/CustomModal";
import LoadingError from "Pages/Error/LoadingError";
import PhoneInput from "react-phone-input-2";

const QuickSignup = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { userCreateShort } = useContext(AuthContext);
  const { userDataShopify, getGeoCoords } = useContext(ApiContext);
  const [userDetail, setUserDetail] = useState(null);
  const [fullDetails, setFullDetails] = useState(null);
  const [productArray, setProductArray] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [error, setError] = useState(null);
  const [errorPage, setErrorPage] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (queryParams?.get("reference")) {
      setUserDetail(JSON.parse(base64Decode(queryParams?.get("reference"))));
    }
  }, []);
  useEffect(() => {
    if (userDetail) {
      //   console.log(userDetail.dataReview[0]);
      setLoading(true);
      let productTemp = [];
      userDetail.dataReview.map((data) => {
        productTemp.push({
          description: data.comment === "" ? "." : data.comment,
          product: {
            order_identifier: data.soft_product.order_identifier,
            identifier: data.soft_product.identifier,
            name: data.soft_product.name,
            handle: data.soft_product.handle,
            image: data.soft_product.image,
            price: data.soft_product.price,
            currency_code: data.soft_product.currency_code,
            tag:
              data.soft_product.tags.length < 1
                ? ""
                : data.soft_product.tags[0].name,
            store: {
              identifier: data.soft_product.soft_store.identifier,
              name: data.soft_product.soft_store.name,
              domain: data.soft_product.soft_store.domain,
              email: data.soft_product.soft_store.email,
            },
          },
        });
        setProductArray(productTemp);
      });
      let res = userDataShopify(
        `?shop_id=${userDetail.dataReview[0].soft_product.soft_store.identifier}&customer_id=${userDetail.dataReview[0].soft_user.customer_identifier}`
      );
      res
        .then((response) => {
          //   console.log(response);
          setLoading(false);
          setFullDetails(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          setErrorPage(error);
        });
    }
  }, [userDetail]);
  if (!loading) {
    return (
      <div className="container">
        <h4>Welcome to Purebrand</h4>
        <p>
          Your profile will be created with the following details. Please verify
          and continue.
        </p>
        <Formik
          initialValues={{
            email: userDetail?.email ? userDetail.email : "",
            first_name: fullDetails?.first_name
              ? capitalizeFirst(fullDetails?.first_name)
              : "",
            last_name: fullDetails
              ? capitalizeFirst(fullDetails?.last_name)
              : "",
            phone_number: fullDetails?.phone ? fullDetails?.phone : "",
            about_me: "",
            address: fullDetails ? fullDetails?.address1 : "",
            city: fullDetails ? fullDetails?.city : "",
            state: fullDetails ? fullDetails?.province : "",
            zip_code: fullDetails ? fullDetails?.zip : "",
          }}
          onSubmit={async (e) => {
            let body = {
              phone_number: e.phone_number,
              about_me: e.about_me,
              location: {
                latitude: "string",
                longitude: "string",
                address: e.address,
                street_number: "",
                city: e.city,
                state: e.state,
                zip_code: e.zip_code,
              },
              user: {
                customer_identifier:
                  userDetail.dataReview[0].soft_user.customer_identifier,
                first_name: e.first_name,
                last_name: e.last_name,
                email: e.email,
              },
              product: productArray,
            };
            let response = await getGeoCoords(e);
            if (response.status === 200 && response.data.results.length > 0) {
              body.location.latitude =
                response.data.results[0].geometry.location.lat;
              body.location.longitude =
                response.data.results[0].geometry.location.lng;
              try {
                let res = await userCreateShort(
                  body,
                  `?signature=${base64Encode(
                    generateSignature(
                      `Hey, this is my customer id: ${userDetail.dataReview[0].soft_user.customer_identifier}`
                    )
                  )}`
                );
                if (res) {
                  setShow(true);
                }
              } catch (error) {
                setError(error?.response?.data?.message);
              }
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="form-group row login-wrapper">
                {error ? (
                  <Alert className="" key="danger" variant="danger">
                    {error}
                  </Alert>
                ) : (
                  <></>
                )}
                <div className="col col-6">
                  <h5>Basic Information</h5>
                  <div className="row">
                    <div className="col col-md-6">
                      <label>Email</label>
                      <Field
                        className={`form-control`}
                        name="email"
                        disabled
                        type="email"
                      />
                    </div>
                    <div className="col col-md-6">
                      <label>Phone</label>

                      <PhoneInput
                        country={"us"}
                        value={values.phone_number}
                        onChange={(e) => setFieldValue("phone_number", e)}
                        className={`custom-phone-ip`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-md-6">
                      <label>First Name</label>
                      <Field
                        className={`form-control`}
                        name="first_name"
                        type="text"
                      />
                    </div>
                    <div className="col col-md-6">
                      <label>Last Name</label>
                      <Field
                        className={`form-control`}
                        name="last_name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-md-4">
                      <label>Address</label>
                      <Field
                        className={`form-control`}
                        name="address"
                        type="text"
                      />
                    </div>
                    <div className="col col-md-3">
                      <label>City</label>
                      <Field
                        className={`form-control`}
                        name="city"
                        type="text"
                      />
                    </div>
                    <div className="col col-md-3">
                      <label>State</label>
                      <Field
                        className={`form-control`}
                        name="state"
                        type="text"
                      />
                    </div>
                    <div className="col col-md-2">
                      <label>Zip</label>
                      <Field
                        className={`form-control`}
                        name="zip_code"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-md-12">
                      <label>About Me</label>
                      <Field
                        className={`form-control`}
                        name="about_me"
                        as="textarea"
                        rows={3}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="col col-6"></div> */}
              </div>
              <Button className="mt-3" type="submit">
                Confirm & Create
              </Button>
              <CustomModal
                showStatus={show}
                clickStatus={handleClose}
                buttonMessage="Close"
                closeStatus={handleClose}
                title="Account Created"
                message="Account Created Successfully. Check your email for login information."
                secondMessage="Go to Login"
                secondButton={true}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  } else if (errorPage) {
    return (
      <LoadingError
        toUrl={"/"}
        status={"404"}
        message={"Failed to fetch user details."}
      />
    );
  } else {
    return <PurebrandLoader />;
  }
};

export default QuickSignup;
