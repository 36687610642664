import React, { useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  InputNumber,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
} from 'antd';
import './DirectorModal.css';
import { MESSAGES } from './constants/messages';
import { PLACEHOLDERS } from './constants/placeholders';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { COUNTRY_OPTIONS, US_STATE_LIST } from 'utils/constants';
import dayjs from 'dayjs';

/**
 * This component renders a modal for directors input
 *
 * @return {ReactNode} A React element that renders a modal for directors input
 */

const DirectorModal = ({
  directorsModalOpen,
  handleModalClose,
  setDirectors,
  directors,
  currentDirectorIndex,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (currentDirectorIndex === undefined) {
      setDirectors([...directors, values]);
      handleModalClose();
    } else {
      const newDirectors = [...directors];
      newDirectors[currentDirectorIndex] = values;
      setDirectors(newDirectors);
      handleModalClose();
    }
  };

  /**
   * This function is to get the disability of the dates in the date picker
   *
   * @return {Array} disabled dates
   */

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const initialValues = () => {
    if (currentDirectorIndex !== undefined) {
      const director = { ...directors[currentDirectorIndex] };
      director.dob = dayjs(director?.dob)
      return director;
    }
    return {};
  };

  if (currentDirectorIndex === undefined) {
    form.resetFields();
  }

  useEffect(() => {
    form.setFieldsValue(initialValues());
  }, [currentDirectorIndex]);

  return (
    <Modal
      open={directorsModalOpen}
      title={<div className="directors-title">Owners Information</div>}
      className="directors-modal"
      onCancel={() => {
        form.resetFields();
        handleModalClose();
      }}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        key={directorsModalOpen}
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
      >
        <div className="director-contact-info">
          <h3>Contact</h3>
          <Row>
            <Col className="custom-col">
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  { required: true, message: MESSAGES.FIRST_NAME_REQUIRED },
                ]}
              >
                <Input placeholder={PLACEHOLDERS.FIRST_NAME} />
              </Form.Item>
            </Col>
            <Col className="custom-col">
              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.LAST_NAME_REQUIRED,
                  },
                ]}
              >
                <Input placeholder={PLACEHOLDERS.LAST_NAME} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="custom-col">
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: MESSAGES.EMAIL_REQUIRED }]}
              >
                <Input placeholder={PLACEHOLDERS.EMAIL} />
              </Form.Item>
            </Col>
            <Col className="custom-col">
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.PHONE_REQUIRED,
                  },
                ]}
              >
                <PhoneInput
                  country={'us'}
                  disableDropdown
                  countryCodeEditable={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="custom-col">
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: MESSAGES.TITLE_REQUIRED }]}
              >
                <Input placeholder={PLACEHOLDERS.TITLE} />
              </Form.Item>
            </Col>
            <Col className="custom-col">
              <Form.Item
                name="ownership_percentage"
                label="Ownership Percentage"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.OWNERSHIP_PERCENTAGE_REQUIRED,
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  max={100}
                  placeholder={PLACEHOLDERS.OWNERSHIP_PERCENTAGE}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="custom-col">
              <Form.Item
                name="dob"
                label="Date of birth"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.DOB_REQUIRED,
                  },
                ]}
              >
                <DatePicker
                  className="popup-calendar"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="director-address-info">
          <h3>Address</h3>
          <Row>
            <Col className="custom-col">
              <Form.Item
                name={['address', 'line1']}
                label="Line 1"
                rules={[{ required: true, message: MESSAGES.LINE1_REQUIRED }]}
              >
                <Input placeholder={PLACEHOLDERS.LINE1} />
              </Form.Item>
            </Col>
            <Col className="custom-col">
              <Form.Item
                name={['address', 'line2']}
                label="Line 2"
              >
                <Input placeholder={PLACEHOLDERS.LINE2} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="custom-col">
              <Form.Item
                name={['address', 'city']}
                label="City"
                rules={[{ required: true, message: MESSAGES.CITY_REQUIRED }]}
              >
                <Input placeholder={PLACEHOLDERS.CITY} />
              </Form.Item>
            </Col>
            <Col className="custom-col">
              <Form.Item
                name={['address', 'zip']}
                label="Postal code"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.ZIP_REQUIRED,
                  },
                ]}
              >
                <Input placeholder={PLACEHOLDERS.ZIP} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="custom-col">
              <Form.Item
                name={['address', 'state']}
                label="State"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.STATE_REQUIRED,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={PLACEHOLDERS.INCORPORATION_STATE}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label.toLocaleLowerCase() ?? '').includes(
                      input.toLocaleLowerCase()
                    )
                  }
                  options={US_STATE_LIST}
                />
              </Form.Item>
            </Col>
            <Col className="custom-col">
              <Form.Item
                name={['address', 'country']}
                label="Country"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.COUNTRY_REQUIRED,
                  },
                ]}
              >
                <Select
                  placeholder={PLACEHOLDERS.COUNTRY}
                  options={COUNTRY_OPTIONS}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DirectorModal;
