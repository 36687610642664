import { UploadImage } from "components/Forms/Multistep/ProfileCompletion/ProfileInfo";
import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import "./profileinfo.css";

const PhotoHandler = (props) => {
  let { user, userProfile } = useContext(AuthContext);
  let { uploader, setupStoreProfile } = useContext(ProfileContext);
  const [avatar, setAvatar] = useState(props.photo);
  const [isEnabled, setIsEnabled] = useState(false);
  const handleSubmit = (values) => {
    setIsEnabled(false);
    if (user?.role === 3 || user?.role === 1) {
      const res = uploader(values);
      res
        .then(function (response) {
          if (response.status === 200) {
            props.closing();
            setIsEnabled(true);
          }
        })
        .catch(function (error) {
          setIsEnabled(true);
        });
    } else if (user?.role === 2) {
      const res = setupStoreProfile(values);
      res
        .then(function (response) {
          if (response.status === 200) {
            props.closing();
            setIsEnabled(true);
          }
        })
        .catch(function (error) {
          setIsEnabled(true);
        });
    }
  };
  const getImage = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event[0]);

    reader.onload = (e) => {
      let img = e.target.result;
      setAvatar(img);

      setIsEnabled(true);
    };
  };

  const getAccounts = () => {
    const array = [];
    userProfile.user_shopify.map((store) =>
      array.push({
        store_email: store.store_email,
      })
    );
    return array;
  };

  const initialValuesHost = {
    phone_number: userProfile.phone_number,
    about_me: userProfile.about_me,
    instagram_handle: userProfile?.user_social?.instagram_handle
      ? userProfile?.user_social?.instagram_handle
      : "",
    twitter_handle: userProfile?.user_social?.twitter_handle
      ? userProfile?.user_social?.twitter_handle
      : "",
    tiktok_handle: userProfile?.user_social?.tiktok_handle
      ? userProfile?.user_social?.tiktok_handle
      : "",
    facebook_handle: userProfile?.user_social?.facebook_handle
      ? userProfile?.user_social?.facebook_handle
      : "",
    latitude: userProfile.user_geo_location.latitude,
    longitude: userProfile.user_geo_location.longitude,
    address: userProfile.user_geo_location.address,
    street_number: userProfile?.user_geo_location.street_number,
    city: userProfile.user_geo_location.city,
    state: userProfile.user_geo_location.state,
    zip_code: userProfile.user_geo_location.zip_code,
    profile_picture_id: userProfile?.profile_picture?.id
      ? userProfile?.profile_picture?.id
      : "",
    store_email: userProfile?.user_shopify[0]?.store_email
      ? userProfile?.user_shopify[0]?.store_email
      : user?.email,
    tag: "profile_picture",
    type: "image",
    extension: "jpg",
    imageFile: "",
    accounts: getAccounts(),
  };
  const initialValuesStore = {
    phone_number: userProfile.phone_number,
    about_me: userProfile.about_me,
    instagram_handle: userProfile.user_social
      ? userProfile?.user_social?.instagram_handle
      : "",
    twitter_handle: userProfile.user_social
      ? userProfile?.user_social?.twitter_handle
      : "",
    tiktok_handle: userProfile.user_social
      ? userProfile?.user_social?.tiktok_handle
      : "",
    facebook_handle: userProfile.user_social
      ? userProfile?.user_social?.facebook_handle
      : "",
    latitude: userProfile.user_geo_location.latitude,
    longitude: userProfile.user_geo_location.longitude,
    address: userProfile.user_geo_location.address,
    street_number: userProfile.user_geo_location.street_number,
    city: userProfile.user_geo_location.city,
    state: userProfile.user_geo_location.state,
    zip_code: userProfile.user_geo_location.zip_code,
    profile_picture_id: userProfile?.profile_picture?.id
      ? userProfile?.profile_picture?.id
      : "",
    store_email: userProfile?.user_shopify[0]?.store_email
      ? userProfile?.user_shopify[0]?.store_email
      : user?.email,
    demoCommission: userProfile?.store?.demo_commission,
    commissionType: userProfile?.store?.demo_commission_type,
    salesCommission: userProfile?.store?.demo_sales_commission,
    salesCommissionType: userProfile?.store?.demo_sales_commission_type,
    no_show_demo_commission: userProfile?.store?.no_show_demo_commission,
    no_show_demo_commission_type:
      userProfile?.store?.no_show_demo_commission_type,
    discountType: userProfile?.store?.discount_type,
    discountGiven: userProfile?.store?.discount_given,
    payment_frequency: userProfile?.store?.payment_frequency,
    tag: "profile_picture",
    type: "image",
    extension: "jpg",
    imageFile: "",
  };

  if (props.status === "watch") {
    return (
      <div className="photo-viewer-full text-center">
        <img src={props.photo} alt="profile" className="photo-full-view" />
      </div>
    );
  } else if (props.status === "edit") {
    return (
      <div className="photo-editer-full text-center">
        <Formik
          initialValues={
            user.role === 3 || user.role === 1
              ? initialValuesHost
              : initialValuesStore
          }
          onSubmit={handleSubmit}
        >
          {({ values, touched, setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="col-sm-12 col-md-6 mt-3 profile_picture_wrap">
                  <div className="form-outline">
                    <div>
                      <img src={avatar} className="img" alt="" />
                    </div>

                    {touched.profile_picture}
                    <ErrorMessage
                      component="div"
                      name="profile_picture"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 mt-3 d-flex profile_picture_upload_wrap justify-content-center">
                  <UploadImage
                    setFieldValue={setFieldValue}
                    value="imageFile"
                    class="uploader-parts"
                    title="Upload Your photo"
                    body="Upload your photo. Make sure you use proper image format. This could help in getting you approved as a host easily. .JPG, png, gif with a file size less than 10MB. Make sure the file name is less than 100 characters long"
                    footer="Click here or drag image here."
                    accept={{ "image/*": [] }}
                  />
                  {values.imageFile && getImage(values.imageFile)}
                </div>
              </div>
              <div className="button-save-photo text-start">
                <button
                  type="submit"
                  className="btn btn-outline-primary mt-3"
                  disabled={!isEnabled}
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
};

export default PhotoHandler;
