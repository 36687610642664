import PurebrandLoader from "components/Loader/PurebrandLoader";
import NavComponent from "components/Navigation/NavComponent";
import DataContext from "context/DataContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import SnippetViewer from "./SnippetViewer";
import ScheduleVideoSnippet from "./ScheduleVideoSnippet";
import "./reviewsoft.css";
import { Tabs } from "react-bootstrap";
import ProductCardSnippet from "./ProductCardSnippet";
import JoinasHost from "./JoinasHost";
import LeaveReview from "./LeaveReview";

const CodeSnippetAdmin = () => {
  const { softReviews } = useContext(DataContext);
  const [loading, setLoading] = useState(true);

  const allReviews = async (params) => {
    setLoading(true);
    try {
      const res = await softReviews(null, params, 1);
      if (res) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(true);
    }
  };

  useEffectOnce(() => {
    allReviews("?order=true&limit=20&offset=0");
  }, []);

  return (
    <>
      {loading ? (
        <PurebrandLoader />
      ) : (
        <div className="container">
          <Tabs
            defaultActiveKey="snippet"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <NavComponent
              eventKey="snippet"
              title={`Rating Snippet`}
              className=" host-request-wrapper"
            >
              <SnippetViewer />
            </NavComponent>
            <NavComponent
              eventKey="demosnippet"
              title={`Schedule Video Demo Snippet`}
              className=" host-request-wrapper"
            >
              <ScheduleVideoSnippet />
            </NavComponent>
            <NavComponent
              eventKey="productsnippet"
              title={`Product Rating Snippet`}
              className=" host-request-wrapper"
            >
              <ProductCardSnippet />
            </NavComponent>
            <NavComponent
              eventKey="hostInvitation"
              title={`Join as Host Snippet`}
              className=" host-request-wrapper"
            >
              <JoinasHost />
            </NavComponent>
            {/* <NavComponent
              eventKey="leaveReview"
              title={`Leave Review Snippet`}
              className=" host-request-wrapper"
            >
              <LeaveReview />
            </NavComponent> */}
          </Tabs>
        </div>
      )}
    </>
  );
};

export default CodeSnippetAdmin;
