import { Icon, BlankImg } from 'assets/images';
import React, { useState } from 'react';
import { useEffectOnce } from 'hooks/useEffectOnce';
import {
  calculateFacetimeDuration,
  calculateVideoDuration,
  convertNoShowQualificationTime,
  convertUTCToLocalAmPmFormat,
} from 'utils/dateFormatter';
import { Rating } from 'react-simple-star-rating';
import PropTypes from 'prop-types';
import CopyIcon from 'components/CopyIcon/CopyIcon';
import moment from 'moment';
import { DATE_FORMATS } from 'utils/constants';

/**
 * This component renders all the demo information for a single demo in the custom modal
 *
 * @return {ReactNode} A React element that renders all the demo information for a single demo in the custom modal
 */

const StorePayDetail = ({
  earning,
  guest,
  host,
  product,
  recording,
  startedAt,
  endedAt,
  soonestAt,
  stars,
  review,
  schedule,
  coupon_code,
}) => {
  const [hostProfile, setHostProfile] = useState();
  const [videoLink, setVideoLink] = useState();
  const [videoLoading, setVideoLoading] = useState(true);

  /**
   * This component renders the scheduled time of a meeting
   *
   * @return {ReactNode} A React element that renders the scheduled time of a meeting
   */

  const getScheduledTime = () => {
    if (soonestAt) {
      return (
        <>
          {
            convertUTCToLocalAmPmFormat(
              `${schedule.scheduled_date}T${schedule.scheduled_time}`
            )[0]
          }
          {'  '}
          ASAP
        </>
      );
    }
    return (
      <>
        {
          convertUTCToLocalAmPmFormat(
            `${schedule.scheduled_date}T${schedule.scheduled_time}`
          )[0]
        }
        {'  '}
        {
          convertUTCToLocalAmPmFormat(
            `${schedule.scheduled_date}T${schedule.scheduled_time}`
          )[1]
        }
      </>
    );
  };

  /**
   * This component renders the renderNoShowQualification time of a meeting
   *
   * @return {ReactNode} A React element that renders the renderNoShowQualification time of a meeting
   */

  const renderNoShowQualification = () => {
    return (
      <>
        {convertNoShowQualificationTime(
          soonestAt,
          startedAt,
          `${schedule.scheduled_date}T${schedule.scheduled_time}`
        )}
      </>
    );
  };

  /**
   * This function is used to get the color of the host joining stat text
   *
   * @return {string} A string for the color of the host joining stat text
   */

  const getHostJoiningStatText = () => {
    if (soonestAt) {
      return '#212529';
    }

    if (!startedAt) {
      startedAt = `${schedule.scheduled_date}T${schedule.scheduled_time}`;
    }

    const hostStartTime = moment.utc(
      startedAt,
      DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
    );

    const scheduledTime = moment.utc(
      `${schedule.scheduled_date}T${schedule.scheduled_time}`,
      DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
    );

    hostStartTime.local();
    scheduledTime.local();

    if (scheduledTime < hostStartTime) return 'red';
    return '#212529';
  };

  /**
   * This function is used to get the color of the host joining stat text
   *
   * @return {string} A string for the color of the host joining stat text
   */

  const getHostLeftStatTextColor = () => {
    if (guest?.attend_meeting) {
      return '#212529';
    }
    const noShowTime = convertNoShowQualificationTime(
      soonestAt,
      startedAt,
      `${schedule.scheduled_date}T${schedule.scheduled_time}`,
      true
    );

    const hostLeftTime = moment.utc(
      endedAt,
      DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
    );

    hostLeftTime.local();

    if (hostLeftTime < noShowTime) return 'red';
    return '#212529';
  };

  /**
   * This component renders the video duration time of a meeting
   *
   * @return {ReactNode} A React element that renders the video duration time of a meeting
   */

  const renderVideoDuration = () => {
    return (
      <>
        {calculateVideoDuration(
          startedAt,
          endedAt,
          guest.attend_meeting,
          guest.left_meeting,
          `${schedule.scheduled_date}T${schedule.scheduled_time}`
        )}
      </>
    );
  };

  /**
   * This component renders the facetime duration time of a meeting
   *
   * @return {ReactNode} A React element that renders the facetime duration time of a meeting
   */

  const renderFacetimeDuration = () => {
    return (
      <>
        {calculateFacetimeDuration(
          startedAt,
          endedAt,
          guest.attend_meeting,
          guest.left_meeting,
          `${schedule.scheduled_date}T${schedule.scheduled_time}`
        )}
      </>
    );
  };

  /**
   * This component renders the statistics of an ended meeting
   *
   * @return {ReactNode} A React element that renders the statistics of an ended meeting for the store admin
   */

  const renderMeetingStats = () => {
    return (
      <>
        <div className="date-time-display">
          <div className="time-header-display">Scheduled</div>
          <div className="time-display">{getScheduledTime()}</div>
        </div>
        <div className="host-joined-display">
          <div className="host-joined-header-display">Host Joined</div>
          <div
            className="host-joined-display"
            style={{ color: getHostJoiningStatText() }}
          >
            {
              convertUTCToLocalAmPmFormat(
                `${startedAt ? startedAt : schedule.scheduled_date}T${
                  schedule.scheduled_time
                }`
              )[1]
            }
          </div>
        </div>
        <div className="no-show-display">
          <div className="no-show-header-display">No Show Qualification</div>
          <div className="no-show-display">{renderNoShowQualification()}</div>
        </div>
        <div className="guest-joined-display">
          <div className="guest-joined-header-display">Guest Joined</div>
          <div className="guest-joined-display">
            {guest.attend_meeting ? (
              <> {convertUTCToLocalAmPmFormat(guest.attend_meeting)[1]}</>
            ) : (
              <>NA</>
            )}
          </div>
        </div>
        <div className="guest-left-display">
          <div className="guest-left-header-display">Guest Left</div>
          <div className="guest-left-display">
            {guest.left_meeting ? (
              <> {convertUTCToLocalAmPmFormat(guest.left_meeting)[1]}</>
            ) : (
              <>NA</>
            )}
          </div>
        </div>
        <div className="host-left-display">
          <div className="host-left-header-display">Host Left</div>
          <div className="host-left-display">
            {endedAt ? (
              <div style={{ color: getHostLeftStatTextColor() }}>
                {' '}
                {convertUTCToLocalAmPmFormat(endedAt)[1]}
              </div>
            ) : (
              <>-</>
            )}
          </div>
        </div>
        <div className="face-time-duration-display">
          <div className="face-time-duration-header-display">
            Facetime Duration
          </div>
          <div className="face-time-duration-display">
            {renderFacetimeDuration()}
          </div>
        </div>
        <div className="video-duration-display">
          <div className="video-duration-header-display">Video Duration</div>
          <div className="video-duration-display">{renderVideoDuration()}</div>
        </div>
      </>
    );
  };

  /**
   * This component renders the screen recording of a demo.
   *
   * @return {ReactNode} A React element that renders the screen recording of a demo.
   */

  const getVideoUI = () => {
    if (videoLoading) {
      return (
        <div>
          <video
            key={videoLink}
            controls
            preload="auto"
            width="100%"
            style={{ borderRadius: '10px' }}
            playsInline
            loop
          >
            <source src={videoLink} type="video/mp4" />
          </video>
          {renderMeetingStats()}
        </div>
      );
    } else {
      if (videoLink) {
        return (
          <div>
            <video
              key={videoLink}
              controls
              width="100%"
              style={{ borderRadius: '10px' }}
              playsInline
              loop
            >
              <source src={videoLink} type="video/mp4" />
            </video>
            {renderMeetingStats()}
          </div>
        );
      } else {
        return 'Recording is corrupted, please contact the admin to get the recording';
      }
    }
  };

  /**
   * This fuction is used to get the video url of the screen recording
   *
   * @return {void}
   */

  const videoUrl = () => {
    const videoResponse = fetch(recording?.cloudinary_file);
    videoResponse
      .then(function (response) {
        if (response && response.status === 200) {
          setVideoLink(response.url);
        } else {
          setVideoLink(recording?.recorded_video);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setVideoLoading(false);
      });
  };

  /**
   * This fuction is used to get the profile picture of the host
   *
   * @return {void}
   */

  const profilePicture = () => {
    const response = fetch(host.profile_picture?.cloudinary_file);
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(host?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffectOnce(() => {
    profilePicture();
    videoUrl();
  }, []);

  return (
    <div className="detail-payment-store-wrapper ">
      <div className="product-wrapper text-center">
        <div className="coupon-details">
          <p className="coupon-header">Coupon Code: {'  '}</p>
          <p> {coupon_code || '-'}</p>
        </div>
        <div className="product-details">
          <p className="product-name-header">Product:</p>
          <p> {product.name}</p>
        </div>
        <div className="product-image-host-guest">
          <div className="image-product-square">
            <img
              className="product-image-circle"
              src={product.image ? product.image : Icon}
              alt="product"
            />
          </div>
          <div className="host-details">
            <h5>Host</h5>
            <div className="host-detail text-start">
              <div className="host-modal-image">
                <img
                  className="host-custom-img"
                  src={
                    host?.profile_picture?.file !== null
                      ? hostProfile
                      : BlankImg
                  }
                  alt="user"
                />
              </div>
              <div className="host-image-details">
                {host.first_name} {host.last_name.charAt(0)}. <br />
                {host.email} <br />
                {host?.user_profile?.phone_number}
              </div>
            </div>
          </div>
          <div className="host-details">
            <h5>Guest</h5>
            <div className="host-detail text-start">
              <div className="host-image-details">
                <p className="guest-detail-lineitem">
                  {<span id="guest_full_name">{guest?.full_name}</span>}{' '}
                  {<CopyIcon elementText={guest?.full_name} />}
                </p>{' '}
                <p className="guest-detail-lineitem">
                  <span>{guest?.email}</span>
                  {<CopyIcon elementText={guest?.email} />}
                </p>
                <p className="guest-detail-lineitem">
                  <span>{guest?.phone_number}</span>{' '}
                  {<CopyIcon elementText={guest?.phone_number} />}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <hr />
      <div className="video-earning-status">
        <div className="video-container">{getVideoUI()}</div>
        <div className="earning-status">
          <p>
            <span className="earnings-header">Status: </span>
            {earning?.earning_paid ? 'Paid' : 'Unpaid'}{' '}
          </p>
          <p>
            <span className="earnings-header">Earnings: </span>{' '}
            {earning?.earning ? `$${earning?.earning}` : '$0.00'}{' '}
          </p>
          <p>
            <span className="earnings-header">Meeting Rating: </span>{' '}
            {stars !== undefined ? (
              <Rating
                size={25}
                readonly
                transition
                allowFraction
                initialValue={stars}
              />
            ) : (
              '-'
            )}{' '}
          </p>
          <p>
            <span className="earnings-header">Host Review: </span>{' '}
            {review ? review : '-'}{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

StorePayDetail.propTypes = {
  earning: PropTypes.object,
  guest: PropTypes.object,
  host: PropTypes.object,
  product: PropTypes.object,
  recording: PropTypes.object,
  startedAt: PropTypes.string,
  endedAt: PropTypes.string,
  soonestAt: PropTypes.string,
  stars: PropTypes.number,
  review: PropTypes.string,
  schedule: PropTypes.object,
  coupon_code: PropTypes.string,
};

export default StorePayDetail;
