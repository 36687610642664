import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";

const ReviewViewTable = ({ review }) => {
  return (
    <tr>
      <td>
        {review.soft_user.first_name} {review.soft_user.last_name.charAt(0)}.
      </td>
      <td>{review.soft_user.email}</td>
      <td>{review.soft_product.name}</td>
      <td>
        <Rating
          size={20}
          readonly
          transition
          allowFraction
          initialValue={review.stars}
        />
      </td>
      <td>
        <ProgressBar
          variant="info"
          className="step-progress-bar"
          now={review.rating}
          min={0}
          max={100}
          label={`${review.rating}/100`}
        />
      </td>
      <td>{review.comment}</td>
      <td>{review.created_at.split("T")[0]}</td>
      <td>
        {new Date(review.created_at).toISOString().split("T")[1].split(".")[0]}
      </td>
    </tr>
  );
};

export default ReviewViewTable;
