import {
  CameraIcon,
  CameraIconS,
  HomeLogo,
  HomeLogoS,
  PaymentIcon,
  PaymentIconS,
  PayoutIcon,
  PayoutIconS,
  ProductIcon,
  ProductIconS,
  SettingIcon,
  SettingIconS,
  CodeSnippet,
  CodeSnippetS,
  ShopifyS,
  Shopify,
} from "assets/images";
import RequestDot from "components/RequestDot/RequestDot";
import AuthContext from "context/AuthContext";
import DemoContext from "context/DemoContext";
import PaymentContext from "context/PaymentContext";
import ProfileContext from "context/ProfileContext";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";

const Sideitems = ({ setIsActive }) => {
  let { user } = useContext(AuthContext);
  let { demoRequests } = useContext(DemoContext);
  let { updatedHosts } = useContext(ProfileContext);
  let { adminPaymentList } = useContext(PaymentContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [location, setLocation] = useState("welcome");

  useEffect(() => {
    setLocation(pathname.split("/").slice(-1)[0]);
  }, [pathname]);

  if (
    user?.role === 2 ||
    user.role === 4 ||
    user.role === 5 ||
    user.role === 6
  ) {
    return (
      <>
        <li
          onClick={() => {
            navigate("/user/welcome");
            setIsActive(false);
          }}
          className={`nav-link ${location === "welcome" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "welcome" ? HomeLogoS : HomeLogo}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Home
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/host");
            setIsActive(false);
          }}
          className={`nav-link ${location === "host" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "host" ? PaymentIconS : PaymentIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Hosts {updatedHosts?.length > 0 && <RequestDot />}
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/store-meetings");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "store-meetings" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "store-meetings" ? CameraIconS : CameraIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Demos
            {demoRequests?.length > 0 && <RequestDot />}
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/accounting");
            setIsActive(false);
          }}
          className={`nav-link ${location === "accounting" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "accounting" ? PayoutIconS : PayoutIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Accounting {adminPaymentList?.length > 0 && <RequestDot />}
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/soft-reviews");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "soft-reviews" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "soft-reviews" ? PaymentIconS : PaymentIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Soft Reviews
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/codeSnippet");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "codeSnippet" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "codeSnippet" ? CodeSnippetS : CodeSnippet}
              alt="home"
              style={{ width: "32px", height: "32px", marginRight: "3px" }}
            />
            Code Snippets
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/shopifyDetails");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "shopifyDetails" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "shopifyDetails" ? ShopifyS : Shopify}
              alt="home"
              style={{ width: "32px", height: "32px", marginRight: "3px" }}
            />
            Shopify Details
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/productsList");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "productsList" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "productsList" ? ProductIconS : ProductIcon}
              alt="home"
              style={{ width: "32px", height: "32px", marginRight: "3px" }}
            />
            Products
          </span>
        </li>
        <hr />
        {(user.role === 2 || user.role === 4) && (
          <li
            onClick={() => {
              navigate("/user/addRole");
              setIsActive(false);
            }}
            className={`nav-link ${
              location === "addRole" ? "active-item" : ""
            }`}
          >
            <span className="text nav-text">
              <img
                src={location === "addRole" ? PaymentIconS : PaymentIcon}
                alt="home"
                style={{ width: "32px", height: "32px" }}
              />
              User Roles
            </span>
          </li>
        )}

        <li
          onClick={() => {
            navigate("/user/settings");
            setIsActive(false);
          }}
          className={`nav-link ${location === "settings" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "settings" ? SettingIconS : SettingIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Settings
          </span>
        </li>
      </>
    );
  } else if (user?.role === 3) {
    return (
      <>
        <li
          onClick={() => {
            navigate("/user/welcome");
            setIsActive(false);
          }}
          className={`nav-link ${location === "welcome" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "welcome" ? HomeLogoS : HomeLogo}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Dashboard
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/meetings");
            setIsActive(false);
          }}
          className={`nav-link ${location === "meetings" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "meetings" ? CameraIconS : CameraIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            My Demos + Requests
            {demoRequests.length > 0 && <RequestDot />}
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/payouts");
            setIsActive(false);
          }}
          className={`nav-link ${location === "payouts" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "payouts" ? PayoutIconS : PaymentIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            My Payouts
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/showrooms");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "showrooms" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "showrooms" ? ProductIconS : ProductIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Products
          </span>
        </li>
        <hr />
        <li
          onClick={() => {
            navigate("/user/settings");
            setIsActive(false);
          }}
          className={`nav-link ${location === "settings" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "settings" ? SettingIconS : SettingIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Settings
          </span>
        </li>
      </>
    );
  } else if (user?.role === 1) {
    return (
      <>
        <li
          onClick={() => {
            navigate("/user/welcome");
            setIsActive(false);
          }}
          className={`nav-link ${location === "welcome" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "welcome" ? HomeLogoS : HomeLogo}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Dashboard
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/store-hosts");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "store-hosts" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "store-hosts" ? PaymentIconS : PaymentIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Hosts
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/companies");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "companies" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "companies" ? PaymentIconS : PaymentIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Companies
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/payments");
            setIsActive(false);
          }}
          className={`nav-link ${location === "payments" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "payments" ? PayoutIconS : PaymentIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Accounting
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/recordings");
            setIsActive(false);
          }}
          className={`nav-link ${
            location === "recordings" ? "active-item" : ""
          }`}
        >
          <span className="text nav-text">
            <img
              src={location === "recordings" ? CameraIconS : CameraIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Recorded Meetings
          </span>
        </li>
        <li
          onClick={() => {
            navigate("/user/review");
            setIsActive(false);
          }}
          className={`nav-link ${location === "review" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "review" ? ProductIconS : ProductIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Review Approvals
          </span>
        </li>{" "}
        <hr />
        <li
          onClick={() => {
            navigate("/user/settings");
            setIsActive(false);
          }}
          className={`nav-link ${location === "settings" ? "active-item" : ""}`}
        >
          <span className="text nav-text">
            <img
              src={location === "settings" ? SettingIconS : SettingIcon}
              alt="home"
              style={{ width: "32px", height: "32px" }}
            />
            Settings
          </span>
        </li>
      </>
    );
  }
};

export default Sideitems;
